import React, { useEffect, useState } from "react";
import axios from "axios";

function Pricing({ url }) {
  const API_URL = process.env.REACT_APP_ADMIN_API_URL;
  const [plans, setPlans] = useState([]);
  const planUrl = `${API_URL}api/owner/page-plans?url=${url}`;
  const staticPlans = [
    {
      id: 1,
      plan_name: "Monthly",
      duration_days: 30,
      price: 1200,
    },
    {
      id: 2,
      plan_name: "Annual",
      duration_days: 365,
      price: 6000,
    },
    {
      id: 3,
      plan_name: "Quaterly",
      duration_days: 90,
      price: 3000,
    },
  ];
  const displayPlans = plans.length > 0 ? plans : staticPlans;
  useEffect(() => {
    axios
      .get(planUrl)
      .then((response) => {
        setPlans(response.data.responseData.data);
      })
      .catch((error) => {
        console.error("Error fetching basic details data:", error);
      });
  }, [planUrl]);
  return (
    <section id="pricing">
      <div className="pricing-upper">
        <div className="section-text__title-centered text-white">Our Plans</div>
      </div>
      <div className="pricing-lower">
        <div className="pricing-cards">
          {displayPlans.map((displayPlan, index) => (
            <div className="pricing-card" key={index}>
              <div className="pricing-card__header">
                <span className="pricing-card__subtitle">
                  {displayPlan.plan_name}
                </span>
                <div className="pricing-card__title">₹{displayPlan.price}</div>
              </div>
              <ul className="pricing-card__features">
                <li>Duration: {displayPlan.duration_days} {displayPlan.duration_days>1? "days": "day"}</li>
              </ul>
              {/* <a href="#pricing" className="pricing-card__btn">
              Get started
            </a> */}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Pricing;
