import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";

function OurTeam({ url }) {
  const API_URL = process.env.REACT_APP_ADMIN_API_URL;
  const [teamMembers, setTeamMembers] = useState([]);
  const teamMemberDetailsUrl = `${API_URL}api/owner/page-team-members?url=${url}`;
  const staticTeamMembers = [
    {
      key:1,
      teamMemberName: "Team Member",
      teamMemberImage: "../images/profile-icon-png-898.png",
      teamMemberDescription: "Team Member Description",
    },
    {
      key:2,
      teamMemberName: "Team Member",
      teamMemberImage: "../images/profile-icon-png-898.png",
      teamMemberDescription: "Team Member Description",
    },
    {
      key:3,
      teamMemberName: "Team Member",
      teamMemberImage: "../images/profile-icon-png-898.png",
      teamMemberDescription: "Team Member Description",
    },
  ];
  const displayTeamMembers =
    teamMembers.length > 0 ? teamMembers : staticTeamMembers;
  useEffect(() => {
    axios
      .get(teamMemberDetailsUrl)
      .then((response) => {
        setTeamMembers(response.data.responseData.data);
      })
      .catch((error) => {
        console.error("Error fetching basic details data:", error);
      });
  }, [teamMemberDetailsUrl]);

  return (
    <section id="our-team" className="bg-emerald-500">
      <div className="section-text">
        <div className="section-text__title-centered">Our Team</div>
        <div className="service-cards">
          {displayTeamMembers.map((teamMember, index) => (
            <div className="service-card" key={index}>
              <div className="service-card__icon">
                <img src={teamMember.teamMemberImage? teamMember.teamMemberImage : "../images/profile-icon-png-898.png"} alt={teamMember.teamMemberName} className="rounded-full"/>
              </div>
              <div className="service-card__text-container">
                <div className="section-text__title-small">
                  {teamMember.teamMemberName}
                </div>
                <div className="section-text__desc">
                  {teamMember.teamMemberDescription}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default OurTeam;
