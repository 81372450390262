import React from "react";
import "../styles/footer.css";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";

function Footer({ url }) {
  const API_URL = process.env.REACT_APP_ADMIN_API_URL;
  const [workingHours, setWorkingHours] = useState({
    email: "",
    mobile: "",
    gym_address: "",
    instaUrl: "",
    facebookUrl: "",
    morning: {
      start: "",
      end: "",
    },
    evening: {
      start: "",
      end: "",
    },
  });

  const workingHoursUrl = `${API_URL}api/owner/page-working-hours?url=${url}`;

  useEffect(() => {
    axios
      .get(workingHoursUrl)
      .then((response) => {
        setWorkingHours(response.data.responseData.data.workingHours);
      })
      .catch((error) => {
        console.error("Error fetching working hours data:", error);
      });
  }, [workingHoursUrl]);

  const convertToFrontendFormat = (time) => {
    let formattedTime = null;
    if (time != null) {
      formattedTime = moment(time, "HH:mm:ss").format("hh:mm A");
    }
    return formattedTime;
  };

  return (
    <footer id="contact" className="bg-emerald-500">
      <div className="container">
        <div className="row">
          <div class="col-30">
            <div class="footer-text__title">Contact Us</div>
            <div class="footer-text__body">
              <p>
                Email:{" "}
                {workingHours.email ? workingHours.email : "info@yourgym.com"}
              </p>
              <p>
                Phone: {workingHours.mobile ? workingHours.mobile : 1234567890}
              </p>
              <p>
                Address:{" "}
                {workingHours.gym_address
                  ? workingHours.gym_address
                  : "Your Gym Address, City"}
              </p>
              <p>
                {workingHours.facebookUrl && (
                  <a
                    href={workingHours.facebookUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faFacebook} />
                  </a>
                )}

                {workingHours.instaUrl && (
                  <a
                    href={workingHours.instaUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-1"
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                )}
              </p>
            </div>
          </div>
          <div className="col-30">
            <div className="footer-text__title">Quick links</div>
            <ul className="footer-list">
              <li>
                <a href="#about-us">About Us</a>
              </li>
              <li>
                <a href="#our-team">Our Team</a>
              </li>
              <li>
                <a href="#our-plans">Our Plans</a>
              </li>
            </ul>
          </div>
          <div className="col-30">
            <div className="footer-text__title">Working Hours</div>
            <div className="footer-text__body">
              <p>Mon-Sat</p>
              <p>
                {"Morning: "}
                {workingHours.morning.start
                  ? convertToFrontendFormat(workingHours.morning.start)
                  : "6:00 AM"}
                {"-"}
                {workingHours.morning.end
                  ? convertToFrontendFormat(workingHours.morning.end)
                  : "10:00 AM"}
              </p>
              <p>
                {"Evening: "}
                {workingHours.evening.start
                  ? convertToFrontendFormat(workingHours.evening.start)
                  : "5:00 PM"}
                {"-"}
                {workingHours.evening.end
                  ? convertToFrontendFormat(workingHours.evening.end)
                  : "10:00 PM"}
              </p>
            </div>
          </div>
        </div>
        <div className="text-center">© SyncWell</div>
      </div>
    </footer>
  );
}

export default Footer;
