import React, { useState, useEffect } from "react";
import { Row, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import axios from "axios";

function HeroBanner({ url }) {
  const API_URL = process.env.REACT_APP_ADMIN_API_URL;
  const [basicDetailsData, setBasicDetailsData] = useState({
    gym_name: "",
    email: "",
    mobile: "",
    gym_address: "",
    title: "",
    body: "",
  });
  const basicDetailsUrl = `${API_URL}api/owner/page-basic-details?url=${url}`;
  useEffect(() => {
    axios
      .get(basicDetailsUrl)
      .then((response) => {
        setBasicDetailsData(response.data.responseData.data);
      })
      .catch((error) => {
        console.error("Error fetching basic details data:", error);
      });
  }, [basicDetailsUrl]);
  return (
    <section id="home" className="bg-emerald-500">
      <Container>
        <Row>
          <div className="home-text col-12">
            <div className="section-text__title-big">
              {basicDetailsData.title
                ? basicDetailsData.title
                : "Welcome to {Your Gym Name} Gym!"}
            </div>
            <div className="section-text__body">
              {basicDetailsData.body
                ? basicDetailsData.body
                : "Achieve your fitness goals with us!"}
            </div>
            <NavLink to={"/admin/dashboard"} className="download-btn">
              Dashboard
            </NavLink>
          </div>
          <div className="section-image col-30">
            <img src="../images/fitness.png" alt="App Preview" />
          </div>
        </Row>
      </Container>
    </section>
  );
}

export default HeroBanner;
