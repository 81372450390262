import React from "react";
import StatisticWidget from "../components/Widget/Statistic.jsx";
import DashboardHeader from "../components/Other/DashboardHeader.jsx";
import NavBar from "../components/Navbar/Index.jsx";
import { useSelector } from "react-redux";

function AdminDashboard() {
  const { user: currentUser } = useSelector((state) => state.auth);
  const accessToken = localStorage.getItem("token");

  if (!accessToken) {
    localStorage.removeItem("token");
    window.location.href = "/auth/login";
  }

  const avatar =
    "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80";

  return (
    <>
      <main className="h-full">
        {/* Welcome Header */}
        {/* <DashboardHeader avatar={avatar} user={currentUser} /> */}
        <NavBar />
        {/* Laba */}
        <div className="px-2 mx-auto mainCard">
          <div className="w-full overflow-hidden text-slate-700 md:grid gap-4 grid md:grid-cols-6">
            <StatisticWidget className="col-span-4 col-start-1 bg-white" />
            {/* <AchievementWidget /> */}
          </div>
        </div>

        {/* OS Kredit */}
        <div className="px-2 mx-auto mainCard">
          {/* <h1 className="text-slate-500 pb-3 text-base md:text-lg">
            Pencapaian OS Kredit
          </h1> */}

          <div className="flex flex-row gap-x-4 overflow-hidden overflow-x-auto justify-between no-scrollbar"></div>

          <div className="lg:w-full w-[1024px] overflow-hidden flex flex-row justify-between text-slate-700 gap-2 lg:max-h-screen overflow-x-auto whitespace-nowrap"></div>
        </div>
      </main>
    </>
  );
}

export default AdminDashboard;
