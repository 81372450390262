import {
  faAddressCard,
  faDumbbell,
  faEnvelope,
  faLock,
  faPerson,
  faUser,
  faEyeSlash,
  faEye,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SignupDoneModal from "../../../components/SignupDoneModal";

function RegisterIndex() {
  let navigate = useNavigate();
  const base_url= process.env.REACT_APP_AUTH_API_URL;
  const storedUser = JSON.parse(localStorage.getItem("user"));
  const [pincode, setPincode] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [gymName, setGymName] = useState("");
  const [gymAddress, setGymAddress] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const msg = "Signup request received. Awaiting approval.";
  const [isModalOpen, setModalOpen] = useState(false);
  const [isOtpVerificationModalOpen, setOtpVerificationModalOpen] = useState(false);
  const [pincodeError, setPincodeError] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [gymNameError, setGymNameError] = useState("");
  const [gymAddressError, setGymAddressError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [otpError, setOtpError] = useState("");
  const { isLoggedIn } = useSelector((state) => state.auth);
  const [ message , setMessage ] = useState("");

  const onChangePincode = (e) => {
    const pincode = e.target.value;
    setPincode(pincode);
    setPincodeError("");
  };

  const onChangeName = (e) => {
    const name = e.target.value;
    setName(name);
    setNameError(""); // Clear any previous error
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
    setEmailError(""); // Clear any previous error
  };

  const onChangeGymName = (e) => {
    const gymName = e.target.value;
    setGymName(gymName);
    setGymNameError("");
  };

  const onChangeGymAddress = (e) => {
    const gymAddress = e.target.value;
    setGymAddress(gymAddress);
    setGymAddressError("");
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
    setPasswordError(""); // Clear any previous error
  };

  const validateForm = () => {
    let valid = true;

    if (pincode.trim() === "") {
      setPincodeError("Pincode cannot be blank");
      valid = false;
    }

    if (name.trim() === "") {
      setNameError("Name cannot be blank");
      valid = false;
    }

    if (!/^\S+@\S+\.\S+$/.test(email)) {
      setEmailError("Invalid email format");
      valid = false;
    }

    if (gymName.trim() === "") {
      setGymNameError("Gym Name cannot be blank");
      valid = false;
    }

    if (gymAddress.trim() === "") {
      setGymAddressError("Gym Address cannot be blank");
      valid = false;
    }

    if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters");
      valid = false;
    }

    return valid;
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const verifyOTP = () => {
    // Make an HTTP request to verify OTP
    axios
      .post(base_url+"api/auth/verify-otp", { email, otp })
      .then((response) => {
        if(response.data.success===true){
          setOtpVerificationModalOpen(false); 
          setModalOpen(true); 
        } else {
          setOtpError("Invalid OTP. Please try again.");
        }
      })
      .catch((error) => {
        setOtpError("Invalid OTP. Please try again.");
        console.error("Error verifying OTP:", error);
      });
  };
  const handleRegister = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      axios
        .post(base_url + "api/auth/gym-owner-signup", {
          name,
          email,
          gymName,
          gymAddress,
          pincode,
          password,
        })
        .then((response) => {
          setLoading(false);
          const success = response.data.success;
          const message = response.data.message;
          if (success) {
            setOtpVerificationModalOpen(true);
          } else if (message==="User Already Exist") {
            setMessage('You already signed up please wait until our team approves your signup! Thank You');
          } else {
            console.error(
              "Registration failed:",
              message || "Unknown error"
            );
            setMessage(message || "Unknown error");
          }
        })
        .catch((error) => {
          console.error("Registration failed:", error);
          setLoading(false);
          setMessage("Failed to register. Please try again later.");
        });
    }
  };
  
  
  
  const handleConfirmModal = () => {
    setModalOpen(false);
    navigate("/auth/login");
  };
  if (isLoggedIn) {
    if (storedUser && storedUser.roles) {
      const roles = storedUser.roles;
      if (roles.includes("ROLE_ADMIN") || roles.includes("ROLE_MOD")) {
        return <Navigate to="/admin/dashboard" />;
      } else {
        return <Navigate to="/user/dashboard" />;
      }
    }
  }

  const registerImage =
    "https://edp.raincode.my.id/static/media/login.cc0578413db10119a7ff.png";
  return (
    <>
      <SignupDoneModal
        isOpen={isModalOpen}
        onConfirm={handleConfirmModal}
        msg={msg}
      />
      {isOtpVerificationModalOpen && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div className="relative bg-white rounded-lg w-full max-w-md p-8 mx-auto">
              <button
                onClick={() => setOtpVerificationModalOpen(false)}
                className="absolute top-0 right-0 p-3"
              >
                <FontAwesomeIcon icon={faTimes} className="text-gray-500" />
              </button>
              <h2 className="text-xl font-semibold mb-4">Verify OTP</h2>
              <div className="mb-4">
                <input
                  type="text"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  className="text-sm placeholder-gray-500 pl-4 pr-12 rounded-lg border border-gray-400 w-full py-3 focus:outline-none focus:border-emerald-400"
                  placeholder="Enter OTP"
                />
                {otpError && (
                  <span className="text-red-500 text-sm mt-1">{otpError}</span>
                )}
              </div>
              <button
                onClick={verifyOTP}
                className="bg-emerald-500 text-white py-2 px-4 rounded-lg text-sm focus:outline-none hover:bg-emerald-700"
              >
                Verify OTP
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="flex min-h-screen">
        <div className="flex w-full flex-col md:flex-row">
          {/* Image */}
          <div className="md:bg-emerald-500 md:min-h-screen flex flex-wrap md:w-1/2">
            <div className="items-center text-center flex flex-col relative justify-center mx-auto">
              <img
                src={registerImage}
                alt="Logo"
                className="md:w-72 w-48 mx-auto"
              />
              <div className="md:block hidden text-slate-100">
                <h1 className="font-semibold text-2xl pb-2">
                  Register an Account
                </h1>
                {/* <span className="text-sm">Free access to gym owners</span> */}
              </div>
            </div>
          </div>
          {/* Register Section */}
          <div className="flex flex-col md:flex-1 items-center justify-center">
            <div className="loginWrapper flex flex-col w-full lg:px-36 md:px-8 px-8 md:py-8">
              {/* Login Header Text */}
              <div className="hidden md:block font-medium self-center text-xl sm:text-3xl text-gray-800">
                Create an Account
              </div>
              {/* Sparator */}
              <div className="hidden md:block relative mt-10 h-px bg-gray-300">
                <div className="absolute left-0 top-0 flex justify-center w-full -mt-2">
                  <span className="bg-white px-4 text-xs text-gray-500 uppercase">
                    Register your account now, it's free!
                  </span>
                </div>
              </div>

              <div className="md:hidden block my-4">
                <h1 className="text-2xl font-semibold">Register</h1>
              </div>

              {/* Register Form */}
              <div className="md:mt-10 mt-4">
                <form onSubmit={handleRegister}>
                  {/* Username */}
                  <div className="flex flex-col mb-3">
                    <div className="relative">
                      <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                        <FontAwesomeIcon icon={faPerson} />
                      </div>

                      <input
                        id="name"
                        type="text"
                        name="name"
                        onChange={onChangeName}
                        className="text-sm placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full md:py-2 py-3 focus:outline-none focus:border-emerald-400"
                        placeholder="Name"
                      />
                    </div>
                    {nameError && (
                      <span className="text-red-500 text-sm mt-1 ml-1">
                        {nameError}
                      </span>
                    )}
                  </div>

                  <div className="flex flex-col mb-3">
                    <div className="relative">
                      <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                        <FontAwesomeIcon icon={faEnvelope} />
                      </div>

                      <input
                        id="email"
                        type="email"
                        name="email"
                        onChange={onChangeEmail}
                        className="text-sm placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full md:py-2 py-3 focus:outline-none focus:border-emerald-400"
                        placeholder="Email"
                      />
                    </div>
                    {emailError && (
                      <span className="text-red-500 text-sm mt-1 ml-1">
                        {emailError}
                      </span>
                    )}
                  </div>
                  <div className="flex flex-col mb-3">
                    <div className="relative">
                      <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                        <FontAwesomeIcon icon={faDumbbell} />
                      </div>

                      <input
                        id="gymName"
                        type="text"
                        name="gymName"
                        onChange={onChangeGymName}
                        className="text-sm placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full md:py-2 py-3 focus:outline-none focus:border-emerald-400"
                        placeholder="Gym Name"
                      />
                    </div>
                    {gymNameError && (
                      <span className="text-red-500 text-sm mt-1 ml-1">
                        {gymNameError}
                      </span>
                    )}
                  </div>
                  <div className="flex flex-col mb-3">
                    <div className="relative">
                      <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                        <FontAwesomeIcon icon={faAddressCard} />
                      </div>

                      <input
                        id="gymAddress"
                        type="text"
                        name="gymAddress"
                        onChange={onChangeGymAddress}
                        className="text-sm placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full md:py-2 py-3 focus:outline-none focus:border-emerald-400"
                        placeholder="Gym Address"
                      />
                    </div>
                    {gymAddressError && (
                      <span className="text-red-500 text-sm mt-1 ml-1">
                        {gymAddressError}
                      </span>
                    )}
                  </div>
                  {/* Username */}
                  <div className="flex flex-col mb-3">
                    <div className="relative">
                      <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                        <FontAwesomeIcon icon={faUser} />
                      </div>

                      <input
                        id="pincode"
                        type="text"
                        name="pincode"
                        onChange={onChangePincode}
                        className="text-sm placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full md:py-2 py-3 focus:outline-none focus:border-emerald-400"
                        placeholder="Pincode"
                      />
                    </div>
                    {pincodeError && (
                      <span className="text-red-500 text-sm mt-1 ml-1">
                        {pincodeError}
                      </span>
                    )}
                  </div>
                  {/* Password */}
                  <div className="flex flex-col mb-3 relative">
                    <div className="relative">
                      <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                        <FontAwesomeIcon icon={faLock} />
                      </div>
                      <input
                        id="password"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={password}
                        onChange={onChangePassword}
                        className="text-sm placeholder-gray-500 pl-10 pr-10 rounded-lg border border-gray-400 w-full md:py-2 py-3 focus:outline-none focus:border-emerald-400"
                        placeholder="Password"
                      />
                      <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        className="absolute right-0 top-0 mt-2 mr-2 focus:outline-none"
                      >
                        <FontAwesomeIcon
                          icon={showPassword ? faEyeSlash : faEye}
                        />
                      </button>
                    </div>
                    {passwordError && (
                      <span className="text-red-500 text-sm mt-1 ml-1">
                        {passwordError}
                      </span>
                    )}
                    {message && (
                      <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                        {message}
                      </span>
                    )}
                  </div>

                  {/* Button Register */}
                  <div className="flex w-full">
                    <button
                      disabled={loading}
                      type="submit"
                      className="flex items-center justify-center focus:outline-none text-white text-sm bg-emerald-500 hover:bg-emerald-700 rounded-lg md:rounded md:py-2 py-3 w-full transition duration-150 ease-in"
                    >
                      <span className="mr-2 md:uppercase">
                        {loading ? "Processing...." : "Register"}
                      </span>
                    </button>
                  </div>
                </form>
              </div>

              {/* Register Link */}
              <div className="flex justify-center items-center my-6 md:mb-0">
                <Link
                  to="/auth/login"
                  className="inline-flex items-center font-bold text-emerald-500 hover:text-emerald-700 text-xs text-center"
                >
                  <span>
                    <svg
                      className="h-6 w-6"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"></path>
                    </svg>
                  </span>
                  <span className="ml-2">Already a user?</span>
                </Link>
              </div>
              <div className="flex justify-center items-center  my-6 md:mb-0">
                <Link
                  to="/"
                  className="inline-flex items-center font-bold text-emerald-500 hover:text-emerald-700 text-xs text-center"
                >
                  Back to Home Page
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RegisterIndex;
