import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import FeedbackTable from "../../pages/user/FeedbackTable";

function Feedback() {
  const API_URL = process.env.REACT_APP_USER_API_URL;
  const { user: currentUser } = useSelector((state) => state.auth);
  const accessToken = currentUser ? currentUser.accessToken : null;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [feedbackError, setFeedbackError] = useState("");
  const [newFeedback, setNewFeedback] = useState({
    message: "",
  });
  const dataHeader = [
    {
      key: "feedback",
      label: "Feedback",
    },
    {
      key: "sentOn",
      label: "Sent On",
    },
    {
      key: "status",
      label: "Status",
    },
    {
      key: "action",
      label: "Action",
    },
  ];
  useEffect(() => {
    if (accessToken) {
      const axiosConfig = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      axios
        .get(API_URL + "/feedback", axiosConfig)
        .then((response) => {
          setData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    } else {
      console.error("Access token not found in localStorage");
    }
  }, [API_URL, accessToken]);

  if (!currentUser) {
    return <Navigate to="/auth/login" />;
  }

  const validate = () => {
    let valid = true;
    if (newFeedback.message.trim() === "") {
      setFeedbackError("Feedback should not be blank");
      valid = false;
    }
    return valid;
  };
  const handleAddFeedback = () => {
    setFeedbackError("");
    if (validate()) {
      const axiosConfig = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      axios
        .post(API_URL + "/add-feedback", newFeedback, axiosConfig)
        .then((response) => {
          axios
            .get(API_URL + "/feedback", axiosConfig)
            .then((response) => {
              setData(response.data);
              setNewFeedback({
                feedback: "",
              });
              setShow(false);
              setLoading(false);
              setShowSuccessMessage(true);
              setTimeout(() => {
                setShowSuccessMessage(false);
              }, 3000);
            })
            .catch((error) => {
              console.error("Error fetching feedback data:", error);
              setLoading(false);
            });
        })
        .catch((error) => {
          console.error("Error adding feedback:", error);
        });
    }
  };
  return (
    <>
      <main className="h-full">
        <div className="mainCard">
          <div className="border w-full border-gray-200 bg-white py-4 px-6 rounded-md">
            <button
              className="bg-emerald-600 text-gray-100 px-3 py-2 rounded-lg shadow-lg text-sm"
              onClick={() => setShow(!show)}
            >
              Add New Feedback
            </button>
            {show && (
              <form className="flex flex-wrap justify-end mt-4">
                <div className="w-full px-2">
                  <label htmlFor="name" className="text-sm text-gray-600">
                    Feedback
                  </label>
                  <textarea
                    id="feedback"
                    type="text"
                    name="message"
                    value={newFeedback.message}
                    onChange={(e) =>
                      setNewFeedback({
                        ...newFeedback,
                        message: e.target.value,
                      })
                    }
                    className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                    placeholder="Write your feedback here"
                  />
                  <span className="text-red-600 text-sm">{feedbackError}</span>{" "}
                  {/* Render the error message */}
                </div>
                <button
                  className="text-emerald-600 border border-gray-100 px-3 py-2 rounded-lg shadow-lg text-sm mt-4"
                  type="button"
                  onClick={() => setShow(false)}
                >
                  Close
                </button>
                <button
                  className="bg-emerald-600 text-gray-100 px-3 py-2 rounded-lg shadow-lg text-sm mt-4 ml-2"
                  type="button"
                  onClick={handleAddFeedback}
                >
                  Add Feedback
                </button>
              </form>
            )}
          </div>
        </div>
        {/* Main Content */}
        <div className="mainCard">
          <div className="border w-full border-gray-200 bg-white py-4 px-6 rounded-md">
            <div
              className={`${
                showSuccessMessage ? "block" : "hidden"
              } bg-green-400 text-white text-center py-2 px-4 mt-4 mb-4 rounded-md`}
            >
              Feedback added successfully!
            </div>
            <FeedbackTable
              loading={loading}
              dataHeader={dataHeader}
              data={data}
            />
          </div>
        </div>
      </main>
    </>
  );
}

export default Feedback;
