import React from "react";
import { Row, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";

function HeroBanner() {
  return (
    <section id="home" className="bg-emerald-500">
      <Container>
        <Row>
          <div className="home-text col-12">
            <div className="section-text__subtitle">Explore the gym's and more</div>
            <div className="section-text__title-big">
              Get your gym companion with SyncWell!
            </div>
            <div className="section-text__body">
              One stop for your all gym related work!
            </div>
            <NavLink to="/auth/login" className="download-btn">
              Get Started
            </NavLink>
          </div>

          <div className="section-image col-30">
            <img src="./images/fitness.png" alt="App Preview" />
          </div>
        </Row>
      </Container>
    </section>
  );
}

export default HeroBanner;
