import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";

function AllDetails() {
  const API_URL = process.env.REACT_APP_USER_API_URL;
  const { user: currentUser } = useSelector((state) => state.auth);
  const accessToken = currentUser ? currentUser.accessToken : null;
  const [data, setData] = useState({});
  const [dietData, setDietData] = useState({});

  useEffect(() => {
    if (accessToken) {
      const axiosConfig = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      axios
        .get(API_URL + "/member-details", axiosConfig)
        .then((response) => {
          setData(response.data);
        })
        .catch((error) => {
          console.error("Error fetching membership data:", error);
        });
        axios
        .get(API_URL + "/diet", axiosConfig)
        .then((response) => {
          setDietData(response.data);
        })
        .catch((error) => {
          console.error("Error fetching diet data:", error);
        });
    } else {
      console.error("Access token not found in localStorage");
    }
  }, [API_URL,accessToken]);

  if (!currentUser) {
    return <Navigate to="/auth/login" />;
  }
  const calculateDaysLeft = (expirationDate) => {
    const currentDate = new Date();
    const expiration = new Date(expirationDate);
    const timeDifference = expiration - currentDate;
    const daysLeft = Math.ceil(timeDifference / (1000 * 3600 * 24));
    return daysLeft;
  };
  return (
    <>
      <main className="h-full">
        <div className="mainCard">
          <div className="bg-white sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                All Details
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Details and informations about your membership and diet.
              </p>
            </div>
          </div>
        </div>
        <div className="mainCard">
          <div className="grid grid-cols-2 gap-4">
            <div className="col-span-2 sm:col-span-1 bg-white shadow overflow-hidden sm:rounded-lg">
              <div class="px-4 py-5 sm:px-6 row">
                <div className="col">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Membership Details
                  </h3>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Name</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {data.name}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Email</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {data.email}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Username</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {data.username}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Membership Plan
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <p className="font-normal text-sm text-gray-500">
                    <span className="rounded-full py-1 px-3 text-xs font-semibold bg-emerald-200 text-green-900">
                      {data.membershipPlanName}
                    </span>
                  </p>
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Day's left
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {calculateDaysLeft(data.expirationDate)} days
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Diet Plan Name
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {data.dietPlanName}
                </dd>
              </div>
            </div>
            <div className="col-span-2 sm:col-span-1 bg-white shadow overflow-hidden sm:rounded-lg">
              <div class="px-4 py-5 sm:px-6 row">
                <div className="col">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Diet Details
                  </h3>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Diet Plan Name
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {dietData.planName}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Description
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {dietData.description}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Protein Sources
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {dietData.proteinSources &&
                  Array.isArray(dietData.proteinSources) &&
                  dietData.proteinSources.length > 0
                    ? dietData.proteinSources.join(", ")
                    : "Not Assigned"}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Carb Sources
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {dietData.carbSources &&
                  Array.isArray(dietData.carbSources) &&
                  dietData.carbSources.length > 0
                    ? dietData.carbSources.join(", ")
                    : "Not Assigned"}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Fat Sources
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {dietData.fatSources &&
                  Array.isArray(dietData.fatSources) &&
                  dietData.fatSources.length > 0
                    ? dietData.fatSources.join(", ")
                    : "Not Assigned"}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Additional Info
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {dietData.additionalInfo}
                </dd>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default AllDetails;
