import React from "react";
import Navbar from "../components/Navbar/Index";
import { useOutletContext, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import GymBasicDetails from "../components/GymLandingPage/components/GymBasicDetails";
import AboutGym from "../components/GymLandingPage/components/AboutGym";
import TeamMember from "../components/GymLandingPage/components/TeamMember";
import WorkingHourTable from "../components/GymLandingPage/components/WorkingHourTable";

function GymDetails() {
  const [sidebarToggle] = useOutletContext();
  const accessToken = localStorage.getItem("token");

  if (!accessToken) {
    return <Navigate to="/auth/login" />;
  }

  return (
    <>
      <main className="h-full">
        <Navbar toggle={sidebarToggle} />
        <div className="mainCard">
          <div className="bg-white sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Gym Page
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Details and informations filled here will be shown at gym page.
              </p>
            </div>
          </div>
        </div>
        <div className="mainCard">
          <div className="grid grid-cols-2 gap-4">
            <GymBasicDetails accessToken={accessToken} />
            <AboutGym accessToken={accessToken} />
            <TeamMember accessToken={accessToken} />
            <WorkingHourTable accessToken={accessToken} />
          </div>
        </div>
      </main>
    </>
  );
}

export default GymDetails;
