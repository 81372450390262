import React, { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

const RenewModal = ({ isOpen, onClose, plans,userId,refresh }) => {
  const API_URL = process.env.REACT_APP_ADMIN_API_URL;
  const { user: currentUser } = useSelector((state) => state.auth);
  const accessToken = currentUser ? currentUser.accessToken : null;
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [message, setMessage] = useState("");
  const handleOnClose = () => {
    setMessage("");
    setSelectedPlan("");
    onClose();
  }
  const handleRenewPlan = () => {
    if (!selectedPlan) {
      setMessage("Please select a plan.");
      return;
    }
  
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
  
    axios.put(`${API_URL}/${userId}/${selectedPlan}/renew`, null, axiosConfig)
      .then((response) => {
        setMessage("");
        setSelectedPlan(null);
        refresh();
        onClose();
      })
      .catch((error) => {
        setMessage("Error renewing plan. Please try again.");
        console.error("Error renewing plan:", error);
      });
  };
  

  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center ${
        isOpen ? "" : "hidden"
      }`}
    >
      <div className="fixed inset-0 bg-black opacity-40"></div>
      <div className="relative bg-white rounded-lg shadow-lg max-w-md w-full p-6">
          <select
          onChange={(e) => setSelectedPlan(e.target.value)}
          className="w-full border p-2 rounded-md mb-4"
        >
          <option value="">Select a Plan</option>
          {plans.map((plan) => (
            <option key={plan.id} value={plan.id}>
              {plan.planName}
            </option>
          ))}
        </select>
        <p>{message}</p>
        <div className="mt-4 flex justify-end">
          <button
            className="px-4 py-2 bg-gray-300 rounded-md mr-4"
            onClick={handleOnClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-emerald-500 text-white rounded-md"
            onClick={handleRenewPlan}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

export default RenewModal;
