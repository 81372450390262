import React, { useEffect, useState } from "react";
import { Navigate, useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import Navbar from "../components/Navbar/Index";
import axios from "axios";
import GymFeedbackTable from "../pages/GymFeedbackTable";
import ActionModal from "../components/ActionModal";

function GymFeedback() {
  const API_URL = process.env.REACT_APP_ADMIN_API_URL;
  const { user: currentUser } = useSelector((state) => state.auth);
  const accessToken = currentUser ? currentUser.accessToken : null;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false);
  const [feedbackId, setFeedbackId] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [sidebarToggle] = useOutletContext();
  const dataHeader = [
    {
      key: "name",
      label: "Name",
    },
    {
      key: "feedback",
      label: "Feedback",
    },
    {
      key: "sentOn",
      label: "Sent At",
    },
    {
      key: "status",
      label: "Status",
    },
    {
      key: "action",
      label: "Action",
    },
  ];
  useEffect(() => {
    if (accessToken) {
      const axiosConfig = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      axios
        .get(API_URL + "/gym/feedback", axiosConfig)
        .then((response) => {
          setData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    } else {
      console.error("Access token not found in localStorage");
    }
  }, [API_URL, accessToken]);

  if (!currentUser) {
    return <Navigate to="/auth/login" />;
  }

  const handleAck = (feedbackId) => {
    setModalOpen(true);
    setFeedbackId(feedbackId);
  };
  const handleMarkAsSeen = (feedbackId) => {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    axios
      .put(`${API_URL}/${feedbackId}/mark-seen`, null, axiosConfig)
      .then((response) => {
        axios
          .get(API_URL + "/gym/feedback", axiosConfig)
          .then((response) => {
            setData(response.data);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            setLoading(false);
          });
      })
      .catch((error) => {
        console.error("Error marking as seen:", error);
      });
  };

  const handleRefresh = () => {
    setShowSuccessMessage(true);
    setTimeout(() => {
      setShowSuccessMessage(false);
    }, 3000);
    if (accessToken) {
      const axiosConfig = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      axios
        .get(API_URL + "/gym/feedback", axiosConfig)
        .then((response) => {
          setData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    } else {
      console.error("Access token not found in localStorage");
    }
  };
  return (
    <>
      <main className="h-full">
      <Navbar toggle={sidebarToggle} />
        <ActionModal
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          feedbackId={feedbackId}
          refresh={handleRefresh}
        />
        {/* Main Content */}
        <div className="mainCard">
          <div className="border w-full border-gray-200 bg-white py-4 px-6 rounded-md">
            <div
              className={`${
                showSuccessMessage ? "block" : "hidden"
              } bg-green-400 text-white text-center py-2 px-4 mt-4 mb-4 rounded-md`}
            >
              Action added successfully!
            </div>
            <GymFeedbackTable
              loading={loading}
              dataHeader={dataHeader}
              data={data}
              action={handleAck}
              markAsSeen={handleMarkAsSeen}
            />
          </div>
        </div>
      </main>
    </>
  );
}

export default GymFeedback;
