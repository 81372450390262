import React from "react";
import { Route, Routes } from "react-router-dom";

import AdminDashBoard from "./pages/AdminDashboard";
import AuthLayout from "./components/Layout/AuthLayout";
import GuestLayout from "./components/Layout/GuestLayout";
import Login from "./pages/auth/Login";
import NotFound from "./pages/NotFound";
import Form from "./pages/Form";
import RegisterIndex from "./pages/auth/Register";
import Plan from "./pages/Plan";
import Member from "./pages/Member";
import LandingPage from "./pages/LandingPage";
import DietPlan from "./pages/DietPlan";
import GymLandingPage from "./pages/GymLandingPage";
import GymDetails from "./pages/GymDetails";
import UserDashboard from "./pages/user/UserDashboard";
import UserLayout from "./components/Layout/UserLayout";
import Membership from "./pages/user/AllDetails";
import Feedback from "./pages/user/Feedback";
import GymFeedback from "./pages/GymFeedback";
function App() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />}></Route>
      <Route path="/gym/:url" element={<GymLandingPage />}></Route>
      <Route path="/" element={<AuthLayout />}>
        <Route path="/admin/dashboard" element={<AdminDashBoard />}></Route>
        <Route path="/members" element={<Member />}></Route>
        <Route path="/plans" element={<Plan />}></Route>
        <Route path="/diet/plans" element={<DietPlan />}></Route>
        <Route path="/gym/details" element={<GymDetails />}></Route>
        <Route path="/gym/feedback" element={<GymFeedback />}></Route>
        <Route path="/404" element={<NotFound />}></Route>
        <Route path="/form" element={<Form />}></Route>
      </Route>
      <Route path="/" element={<UserLayout />}>
        <Route path="/user/dashboard" element={<UserDashboard />}></Route>
        <Route path="/user/details" element={<Membership />}></Route>
        <Route path="/user/feedback" element={<Feedback />}></Route>
      </Route>
      <Route path="/auth" element={<GuestLayout />}>
        <Route path="/auth/login" element={<Login />}></Route>
        <Route path="/auth/register" element={<RegisterIndex />}></Route>
      </Route>
    </Routes>
  );
}

export default App;
