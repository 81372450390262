import React from "react";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { authHeader } from "../../../services/auth-header";
function TeamMember({ accessToken }) {
  const API_URL = process.env.REACT_APP_ADMIN_API_URL;

  const [isEditingTeam, setIsEditingTeam] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [editedTeamMembers, setEditedTeamMembers] = useState({});
  const [userId, setUserId] = useState("");
  useEffect(() => {
    if (accessToken) {
      const axiosConfig = {
        headers: {
          ...authHeader(),
        },
      };
      axios
        .get(API_URL + "api/owner/gym-team-members", axiosConfig)
        .then((response) => {
          setUserId(response.data.responseData.userId);
          setTeamMembers(response.data.responseData.data);
        })
        .catch((error) => {
          console.error("Error fetching basic details data:", error);
        });
    } else {
      console.error("Access token not found in localStorage");
    }
  }, [API_URL, accessToken]);
  const handleTeamMemberEditClick = () => {
    setIsEditingTeam(!isEditingTeam);
  };

  const handleTeamMemberInputChange = (e, index) => {
    const { name, value } = e.target;
    setTeamMembers((prevMembers) => {
      const updatedMembers = [...prevMembers];
      updatedMembers[index] = {
        ...updatedMembers[index],
        [name]: value,
        hasChangedImage: false,
      };
      return updatedMembers;
    });
    setEditedTeamMembers((prevEditedMembers) => ({
      ...prevEditedMembers,
      [index]: { ...prevEditedMembers[index], [name]: value },
    }));
  };
  const handleTeamMemberSaveClick = () => {
    const requestBody = {
      teamMembers: Object.keys(editedTeamMembers).map(
        (key) => teamMembers[key]
      ),
    };

    const axiosConfig = {
      headers: {
        ...authHeader(),
      },
    };

    axios
      .post(API_URL + "api/owner/edit-team-members", requestBody, axiosConfig)
      .then((response) => {
        axios
          .get(API_URL + "api/owner/gym-team-members", axiosConfig)
          .then((response) => {
            setTeamMembers(response.data.responseData.data);
            setIsEditingTeam(false);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      })
      .catch((error) => {
        console.error("Error creating/updating team:", error);
      });
  };

  const handleImageUpload = (e, index, id) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTeamMembers = [...teamMembers];
        const updatedTeamMember = {
          ...updatedTeamMembers[index],
          teamMemberImage: event.target.result,
          hasChangedImage: true,
        };
        updatedTeamMembers[index] = updatedTeamMember;
        setTeamMembers(updatedTeamMembers);

        const formData = new FormData();
        formData.append("file", file);
        formData.append("teamMemberId", id);
        formData.append("userId", userId);

        axios
          .post(API_URL + "api/owner/upload", formData, {
            headers: {
              ...authHeader(),
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log("Image uploaded successfully:", response.data);
          })
          .catch((error) => {
            console.error("Error uploading image:", error);
          });
      };
      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <div className="col-span-2 sm:col-span-1 bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="bg-white sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6 row">
            <div className="col">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Team Members
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Details and informations about gym team members.
              </p>
            </div>
            {isEditingTeam ? (
              <div>
                <button
                  onClick={handleTeamMemberEditClick}
                  className="text-emerald-600 border border-emerald-300 px-3 py-2 rounded-lg shadow-lg text-sm"
                >
                  Cancel
                </button>
                <button
                  onClick={handleTeamMemberSaveClick}
                  className="bg-emerald-600 text-gray-100 px-3 py-2 rounded-lg shadow-lg text-sm ml-2"
                >
                  Save
                </button>
              </div>
            ) : (
              <button
                onClick={handleTeamMemberEditClick}
                className="bg-emerald-600 text-gray-100 px-3 py-2 rounded-lg shadow-lg text-sm"
              >
                Edit
              </button>
            )}
          </div>
        </div>
        {isEditingTeam ? (
          // Edit mode
          <form>
            {teamMembers.map((teamMember, index) => (
              <div
                key={index}
                className={`${
                  index % 2 === 0 ? "bg-gray-50" : "bg-white"
                } px-4 py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6 items-center`}
              >
                <div className="sm:col-span-1">
                  <label
                    htmlFor={`imageUpload-${index}`}
                    className="cursor-pointer"
                  >
                    <img
                      src={
                        teamMember.teamMemberImage
                          ? teamMember.teamMemberImage
                          : "../images/profile-icon-png-898.png"
                      }
                      alt={teamMember.teamMemberName}
                      className="w-16 h-16 rounded-full border"
                    />
                  </label>
                </div>
                <input
                  type="file"
                  accept="image/*"
                  id={`imageUpload-${index}`}
                  className="hidden"
                  onChange={(e) => handleImageUpload(e, index, teamMember._id)}
                />
                <div className="sm:col-span-3">
                  <dt className="text-sm font-medium text-gray-500">
                    <input
                      type="text"
                      name="teamMemberName"
                      value={teamMember.teamMemberName === "Team Member" ? "" : teamMember.teamMemberName}
                      onChange={(e) => handleTeamMemberInputChange(e, index)}
                      className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                      placeholder="Team Member Name"
                    />
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    <textarea
                      type="text"
                      name="teamMemberDescription"
                      value={teamMember.teamMemberDescription === "Team Member Description" ? "" : teamMember.teamMemberDescription}
                      onChange={(e) => handleTeamMemberInputChange(e, index)}
                      className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                      placeholder="Team Member Description"
                    />
                  </dd>
                </div>
              </div>
            ))}
          </form>
        ) : (
          // Display mode
          <div>
            {teamMembers.map((teamMember, index) => (
              <div
                key={index}
                className={`${
                  index % 2 === 0 ? "bg-gray-50" : "bg-white"
                } px-4 py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6 items-center`}
              >
                <div className="sm:col-span-1">
                  <img
                    src={
                      teamMember.teamMemberImage
                        ? teamMember.teamMemberImage
                        : "../images/profile-icon-png-898.png"
                    }
                    alt={teamMember.teamMemberName}
                    className="w-16 h-16 rounded-full border"
                  />
                </div>
                <div className="sm:col-span-3">
                  <dt className="text-sm font-medium text-gray-500">
                    {teamMember.teamMemberName}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {teamMember.teamMemberDescription}
                  </dd>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}

export default TeamMember;
