import React from "react";
import "../components/GymLandingPage/styles/styles.css";
import Navbar from "../components/GymLandingPage/components/Navbar";
import HeroBanner from "../components/GymLandingPage/components/HeroBanner";
import OurTeam from "../components/GymLandingPage/components/OurTeam";
import Pricing from "../components/GymLandingPage/components/Pricing";
import Footer from "../components/GymLandingPage/components/Footer";
import AboutUs from "../components/GymLandingPage/components/AboutUs";
import { useParams } from 'react-router-dom';


function GymLandingPage() {
  
  const { url} = useParams();
  
  return (
    <>
    <Navbar />
    <main>
      <HeroBanner url={url}/>
      <AboutUs url={url}/>
      <OurTeam url={url}/>
      <Pricing url={url}/>
    </main>
    <Footer url={url} />
  </>
  );
}

export default GymLandingPage;