import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar/Index";
import { useOutletContext } from "react-router-dom";
import axios from "axios";
import DietPlanTable from "./DietPlanTable";
import ConfirmationModal from "../components/ConfirmationModal";
import { authHeader } from "../services/auth-header";
function DietPlan() {
  const API_URL = process.env.REACT_APP_ADMIN_API_URL;
  const [sidebarToggle] = useOutletContext();

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [newDietPlan, setNewDietPlan] = useState({
    plan_name: "",
    description: "",
    protein_sources: [],
    carb_sources: [],
    fat_sources: [],
    additionalInfo: "",
  });
const msg = "Are you sure you want to delete this diet plan?";
  const [nameError, setNameError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [proteinSourcesError, setProteinSourcesError] = useState("");
  const [carbSourcesError, setCarbSourcesError] = useState("");
  const [fatSourcesError, setFatSourcesError] = useState("");
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [dietPlanToDelete, setDietPlanToDelete] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);
  const accessToken = localStorage.getItem("token");

  const dataHeader = [
    {
      key: "name",
      label: "Name",
    },
    {
      key: "description",
      label: "Description",
    },
    {
      key: "proteinSources",
      label: "Protein Sources",
    },
    {
      key: "carbSources",
      label: "Carb Sources",
    },
    {
      key: "fatSources",
      label: "Fat Sources",
    },
    {
      key: "additionalInfo",
      label: "Additional Info",
    },
    {
      key: "action",
      label: "Action",
    },
  ];
  const validateForm = () => {
    let valid = true;

    if (newDietPlan.plan_name.trim() === "") {
      setNameError("Name is required");
      valid = false;
    }

    if (newDietPlan.description.trim() === "") {
      setDescriptionError("Description is required");
      valid = false;
    }
   
    return valid;
  };
  const handleDelete = (planId) => {
    setDeleteModalOpen(true);
    setDietPlanToDelete(planId);
  };
  const handleAddPlan = () => {
    setNameError("");
    setDescriptionError("");
    setProteinSourcesError("");
    setCarbSourcesError("");
    setFatSourcesError("");
    if (validateForm()) {
      const axiosConfig = {
        headers: {
          ...authHeader()
        },
      };

      axios
        .post(API_URL+
          "api/owner/create-diet-plan",
          newDietPlan,
          axiosConfig
        )
        .then((response) => {
          axios
            .get(API_URL+"api/owner/diet-plans", axiosConfig)
            .then((response) => {
              setData(response.data.responseData.data);
              setNewDietPlan({
                plan_name: "",
                description: "",
                protein_sources: [],
                carb_sources: [],
                fat_sources: [],
                additionalInfo: "",
              });
              setShow(false);
              setLoading(false);
              setShowSuccessMessage(true);
              setTimeout(() => {
                setShowSuccessMessage(false);
              }, 3000);
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
              setLoading(false);
            });
        })
        .catch((error) => {
          console.error("Error creating plan:", error);
        });
    }
  };

  const handleConfirmDelete = () => {
    setDeleteModalOpen(false);
    const axiosConfig = {
      headers: {
        ...authHeader()
      },
    };

    axios
      .delete(API_URL+
        `api/owner/delete-diet-plan/?plan_id=${dietPlanToDelete}`,
        axiosConfig
      )
      .then((response) => {
        const updatedData = data.filter((plan) => plan._id !== dietPlanToDelete);
        setData(updatedData);
        setShowDeleteMessage(true);
        setTimeout(() => {
          setShowDeleteMessage(false);
        }, 3000);
      })
      .catch((error) => {
        console.error("Error deleting plan:", error);
      });
  };

  useEffect(() => {
    if (accessToken) {
      const axiosConfig = {
        headers: {
          ...authHeader(),
        },
      };
      axios
        .get(API_URL+"api/owner/diet-plans", axiosConfig)
        .then((response) => {
          setData(response.data.responseData.data); 
          console.log(response.data.responseData.data);
          setLoading(false); 
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false); 
        });
    } else {
      console.error("Access token not found in localStorage");
      setLoading(false); 
    }
  }, [API_URL,accessToken]);

  return (
    <>
      <main className="h-full">
      <ConfirmationModal
          isOpen={isDeleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={handleConfirmDelete}
          msg={msg}
        />
        <Navbar toggle={sidebarToggle} />
        <div className="mainCard">
          <div className="border w-full border-gray-200 bg-white py-4 px-6 rounded-md">
            <button
              className="bg-emerald-600 text-gray-100 px-3 py-2 rounded-lg shadow-lg text-sm"
              onClick={() => setShow(!show)}
            >
              Add New Diet Plan
            </button>
            {show && (
              <form className="flex flex-wrap justify-end mt-4">
                <div className="w-full md:w-1/2 lg:w-1/3 px-2">
                  <label htmlFor="name" className="text-sm text-gray-600">
                    Name
                  </label>
                  <input
                    id="name"
                    type="text"
                    name="name"
                    value={newDietPlan.plan_name}
                    onChange={(e) =>
                      setNewDietPlan({
                        ...newDietPlan,
                        plan_name: e.target.value,
                      })
                    }
                    className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                    placeholder="Name"
                  />
                  <span className="text-red-600 text-sm">{nameError}</span>
                </div>

                <div className="w-full md:w-1/2 lg:w-1/3 px-2">
                  <label
                    htmlFor="description"
                    className="text-sm text-gray-600"
                  >
                    Description
                  </label>
                  <input
                    id="description"
                    type="text"
                    name="description"
                    value={newDietPlan.description}
                    onChange={(e) =>
                      setNewDietPlan({
                        ...newDietPlan,
                        description: e.target.value,
                      })
                    }
                    className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                    placeholder="Description"
                  />
                  <span className="text-red-600 text-sm">
                    {descriptionError}
                  </span>
                </div>

                <div className="w-full md:w-1/2 lg:w-1/3 px-2">
                  <label
                    htmlFor="proteinSources"
                    className="text-sm text-gray-600"
                  >
                    Protein Sources
                  </label>
                  <input
                    id="proteinSources"
                    type="text"
                    name="protein_sources"
                    value={newDietPlan.protein_sources}
                    onChange={(e) =>
                      setNewDietPlan({
                        ...newDietPlan,
                        protein_sources: e.target.value,
                      })
                    }
                    className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                    placeholder="Protein Sources (comma-separated)"
                  />
                  <span className="text-gray-500 text-xs">
                    (e.g., Lean Meat, Fish)
                  </span><br></br>
                  <span className="text-red-600 text-sm">
                    {proteinSourcesError}
                  </span>
                </div>

                <div className="w-full md:w-1/2 lg:w-1/3 px-2">
                  <label
                    htmlFor="carbSources"
                    className="text-sm text-gray-600"
                  >
                    Carb Sources
                  </label>
                  <input
                    id="carbSources"
                    type="text"
                    name="carb_sources"
                    value={newDietPlan.carb_sources}
                    onChange={(e) =>
                      setNewDietPlan({
                        ...newDietPlan,
                        carb_sources: e.target.value,
                      })
                    }
                    className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                    placeholder="Carb Sources (comma-separated)"
                  />
                  <span className="text-gray-500 text-xs">
                    (e.g., Whole Grains, Vegetables)
                  </span><br></br>
                  <span className="text-red-600 text-sm">
                    {carbSourcesError}
                  </span>
                </div>

                <div className="w-full md:w-1/2 lg:w-1/3 px-2">
                  <label htmlFor="fatSources" className="text-sm text-gray-600">
                    Fat Sources
                  </label>
                  <input
                    id="fatSources"
                    type="text"
                    name="fat_sources"
                    value={newDietPlan.fat_sources}
                    onChange={(e) =>
                      setNewDietPlan({
                        ...newDietPlan,
                        fat_sources: e.target.value,
                      })
                    }
                    className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                    placeholder="Fat Sources (comma-separated)"
                  />
                  <span className="text-gray-500 text-xs">
                    (e.g., Nuts, Olive Oil)
                  </span><br></br>
                  <span className="text-red-600 text-sm">
                    {fatSourcesError}
                  </span>
                </div>

                <div className="w-full md:w-1/2 lg:w-1/3 px-2">
                  <label
                    htmlFor="additionalInfo"
                    className="text-sm text-gray-600"
                  >
                    Additional Info
                  </label>
                  <input
                    id="additional_info"
                    type="text"
                    name="additional_info"
                    value={newDietPlan.additional_info}
                    onChange={(e) =>
                      setNewDietPlan({
                        ...newDietPlan,
                        additional_info: e.target.value,
                      })
                    }
                    className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                    placeholder="Additional Info"
                  />
                </div>
                    <button className="text-emerald-600 border border-gray-100 px-3 py-2 rounded-lg shadow-lg text-sm mt-4"
                  type="button"
                  onClick={()=>setShow(false)}
                >
                  Close</button>
                <button
                  className="bg-emerald-600 text-gray-100 px-3 py-2 rounded-lg shadow-lg text-sm mt-4 ml-2"
                  type="button"
                  onClick={handleAddPlan}
                >
                  Add Diet Plan
                </button>
              </form>
            )}
          </div>
        </div>
        {/* Main Content */}
        <div className="mainCard">
          <div className="border w-full border-gray-200 bg-white py-4 px-6 rounded-md">
          <div
              className={`${
                showSuccessMessage ? "block" : "hidden"
              } bg-green-400 text-white text-center py-2 px-4 mt-4 mb-4 rounded-md`}
            >
              Plan added successfully!
            </div>
            <div
              className={`${
                showDeleteMessage ? "block" : "hidden"
              } bg-red-400 text-white text-center py-2 px-4 mt-4 mb-4 rounded-md`}
            >
              Plan deleted successfully!
            </div>
            <DietPlanTable
              loading={loading}
              dataHeader={dataHeader}
              data={data}
              handleDelete={handleDelete}
            />
          </div>
        </div>
      </main>
    </>
  );
}

export default DietPlan;
