import React, { useState, useEffect } from 'react';
import Chart from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';

Chart.register();

function Statistic({ ...props }) {
  const API_URL = process.env.REACT_APP_ADMIN_API_URL;
  const [chartData, setChartData] = useState(null);
  const userData = JSON.parse(localStorage.getItem("user"));
  const accessToken = userData ? userData.accessToken : null;
  useEffect(() => {
    async function fetchData() {
      try {
        const axiosConfig = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
        const response = await axios.get(API_URL+'/membership-price-last-12-months',axiosConfig);
        const data = response.data;

        if (data) {
          const labels = Object.keys(data); // Extract months as labels
          const values = Object.values(data).map((monthData) => monthData.totalMembershipPrice);
          const chartData = {
            labels: labels,
            datasets: [
              {
                label: new Date().getFullYear().toString(),
                data: values,
              },
            ],
          };

          setChartData(chartData);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchData();
  }, [API_URL,accessToken]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 2,
  };

  return (
    <div className={`widgetCard p-3 md:py-4 md:px-6 ${props.className}`}>
      <h1 className="text-medium font-semibold pb-4">Income Statement</h1>
      <div className="">
        {chartData ? <Bar data={chartData} options={options} /> : 'Loading...'}
      </div>
    </div>
  );
}

export default Statistic;
