import React from "react";
import Datatables from "../../components/Datatables/Table";
import DietTableCell from "../../components/Datatables/DietTableCell";

function Feedbacktable({ loading, dataHeader, data }) {
  return (
    <Datatables loading={loading} dataHeader={dataHeader}>
      {data?.map((row, index) => (
        <tr
          key={index}
          className="bg-white border md:border-b block md:table-row rounded-md shadow-md md:rounded-none md:shadow-none mb-5"
        >
          <DietTableCell dataLabel="Feedback" showLabel={true}>
            <span className="font-medium text-sm text-gray-900">
              {row.message}
            </span>
          </DietTableCell>
          <DietTableCell dataLabel="Sent On" showLabel={true}>
            <p className="font-normal text-sm text-gray-500">{new Date(row.createdAt).toLocaleString()}</p>
          </DietTableCell>
          <DietTableCell dataLabel="Status" showLabel={true}>
            <p className="font-normal text-sm text-gray-500">{row.seen? `Seen at ${new Date(row.seenAt).toLocaleString()}` : "Delivered"} </p>
          </DietTableCell>
          <DietTableCell dataLabel="Action" showLabel={true}>
            <p className="font-normal text-sm text-gray-500">{row.actionTaken? row.actionTaken : "No Action"}</p>
          </DietTableCell>
        </tr>
      ))}
    </Datatables>
  );
}

export default Feedbacktable;
