import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar/Index";
import { useOutletContext } from "react-router-dom";
import PlanTable from "./PlanTable";
import axios from "axios";
import ConfirmationModal from "../components/ConfirmationModal";
import { authHeader } from "../services/auth-header";
function Plan() {
  const API_URL = process.env.REACT_APP_ADMIN_API_URL;

  const [sidebarToggle] = useOutletContext();

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [newPlan, setNewPlan] = useState({
    plan_name: "",
    price: "",
    duration_days: "",
  });

  const [nameError, setNameError] = useState("");
  const [priceError, setPriceError] = useState("");
  const [durationError, setDurationError] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);
  const accessToken = localStorage.getItem("token");
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [planToDelete, setPlanToDelete] = useState(null);
  const dataHeader = [
    {
      key: "name",
      label: "Name",
    },
    {
      key: "price",
      label: "Price",
    },
    {
      key: "duration",
      label: "Duration",
    },
    {
      key: "action",
      label: "Action",
    },
  ];
 const msg = "Are you sure you want to delete this plan?";
  const handleAddPlan = () => {
    setNameError("");
    setPriceError("");
    setDurationError("");

    if (newPlan.plan_name.trim() === "") {
      setNameError("Name is required");
      return;
    }
    if (newPlan.price.trim() === "") {
      setPriceError("Price is required");
      return;
    }
    if (newPlan.duration_days.trim() === "") {
      setDurationError("Duration is required");
      return;
    }

    const axiosConfig = {
      headers: {
        ...authHeader(),
      },
    };

    axios
      .post(API_URL + "api/owner/create-plan", newPlan, axiosConfig)
      .then((response) => {
        if(response.data.success){
          setNewPlan({
            plan_name: "",
            price: "",
            duration_days: "",
          });
        }
        axios
          .get(API_URL + "api/owner/plans", axiosConfig)
          .then((response) => {
            setData(response.data.responseData.data);
            
            setShow(false);
            setLoading(false);
            setShowSuccessMessage(true);
            setTimeout(() => {
              setShowSuccessMessage(false);
            }, 3000);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            setLoading(false);
          });
      })
      .catch((error) => {
        console.error("Error creating plan:", error);
      });
  };

  const handleDelete = (planId) => {
    setDeleteModalOpen(true);
    setPlanToDelete(planId);
  };

  const handleConfirmDelete = () => {
    setDeleteModalOpen(false);
    const axiosConfig = {
      headers: {
        ...authHeader(),
      },
    };

    axios
      .delete(API_URL + `api/owner/delete-plan/?plan_id=${planToDelete}`, axiosConfig)
      .then((response) => {
        const updatedData = data.filter((plan) => plan._id !== planToDelete);
        setData(updatedData);
        setShowDeleteMessage(true);
        setTimeout(() => {
          setShowDeleteMessage(false);
        }, 3000);
      })
      .catch((error) => {
        console.error("Error deleting plan:", error);
      });
  };

  useEffect(() => {
    if (accessToken) {
      const axiosConfig = {
        headers: {
          ...authHeader(),
        },
      };
      axios
        .get(API_URL + "api/owner/plans", axiosConfig)
        .then((response) => {
          setData(response.data.responseData.data); 
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false); // Handle the error and set loading to false
        });
    } else {
      console.error("Access token not found in localStorage");
      setLoading(false); // Set loading to false to indicate an error
    }
  }, [API_URL,accessToken]);

  return (
    <>
      <main className="h-full">
        <ConfirmationModal
          isOpen={isDeleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={handleConfirmDelete}
          msg={msg}
        />
        <Navbar toggle={sidebarToggle} />
        <div className="mainCard">
          <div className="border w-full border-gray-200 bg-white py-4 px-6 rounded-md">
            <button
              className="bg-emerald-600 text-gray-100 px-3 py-2 rounded-lg shadow-lg text-sm"
              onClick={() => setShow(!show)}
            >
              Add New Plan
            </button>
            {show && (
              <form className="flex flex-wrap justify-end mt-4">
                <div className="w-full md:w-1/3 px-2">
                  <label htmlFor="name" className="text-sm text-gray-600">
                    Name
                  </label>
                  <input
                    id="name"
                    type="text"
                    name="name"
                    value={newPlan.plan_name}
                    onChange={(e) =>
                      setNewPlan({ ...newPlan, plan_name: e.target.value })
                    }
                    className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                    placeholder="Name"
                  />
                  <span className="text-red-600 text-sm">{nameError}</span>{" "}
                  {/* Render the error message */}
                </div>

                <div className="w-full md:w-1/3 px-2">
                  <label htmlFor="planPrice" className="text-sm text-gray-600">
                    Price
                  </label>
                  <input
                    id="planPrice"
                    type="number"
                    name="price"
                    value={newPlan.price}
                    onChange={(e) =>
                      setNewPlan({ ...newPlan, price: e.target.value })
                    }
                    className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                    placeholder="Price"
                  />
                  <span className="text-red-600 text-sm">{priceError}</span>{" "}
                  {/* Render the error message */}
                </div>

                <div className="w-full md:w-1/3 px-2">
                  <label htmlFor="duration" className="text-sm text-gray-600">
                    Duration
                  </label>
                  <input
                    id="duration"
                    type="number"
                    name="duration"
                    value={newPlan.duration_days}
                    onChange={(e) =>
                      setNewPlan({ ...newPlan, duration_days: e.target.value })
                    }
                    className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                    placeholder="Duration in days"
                  />
                  <span className="text-red-600 text-sm">{durationError}</span>{" "}
                  {/* Render the error message */}
                </div>
                <button
                  className="text-emerald-600 border border-gray-100 px-3 py-2 rounded-lg shadow-lg text-sm mt-4"
                  type="button"
                  onClick={() => setShow(false)}
                >
                  Close
                </button>
                <button
                  className="bg-emerald-600 text-gray-100 px-3 py-2 rounded-lg shadow-lg text-sm mt-4 ml-2"
                  type="button"
                  onClick={handleAddPlan}
                >
                  Add Plan
                </button>
              </form>
            )}
          </div>
        </div>
        {/* Main Content */}
        <div className="mainCard">
          <div className="border w-full border-gray-200 bg-white py-4 px-6 rounded-md">
            <div
              className={`${
                showSuccessMessage ? "block" : "hidden"
              } bg-green-400 text-white text-center py-2 px-4 mt-4 mb-4 rounded-md`}
            >
              Plan added successfully!
            </div>
            <div
              className={`${
                showDeleteMessage ? "block" : "hidden"
              } bg-red-400 text-white text-center py-2 px-4 mt-4 mb-4 rounded-md`}
            >
              Plan deleted successfully!
            </div>
            <PlanTable
              loading={loading}
              dataHeader={dataHeader}
              data={data}
              handleDelete={handleDelete}
            />
          </div>
        </div>
      </main>
    </>
  );
}

export default Plan;
