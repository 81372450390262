import React from "react";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import { authHeader } from "../../../services/auth-header";

function WorkingHourTable({ accessToken }) {
  const API_URL = process.env.REACT_APP_ADMIN_API_URL;

  const [isEditingWorkingHour, setIsEditingWorkingHour] = useState(false);
  const [workingHours, setWorkingHours] = useState({
    morning: {
      start: "",
      end: "",
    },
    evening: {
      start: "",
      end: "",
    },
  });

  useEffect(() => {
    if (accessToken) {
      const axiosConfig = {
        headers: {
          ...authHeader(),
        },
      };
      axios
        .get(API_URL + "api/owner/gym-working-hours", axiosConfig)
        .then((response) => {
          setWorkingHours(response.data.responseData.data.workingHours);
        })
        .catch((error) => {
          console.error("Error fetching gym working hours data:", error);
        });
    } else {
      console.error("Access token not found in localStorage");
    }
  }, [API_URL, accessToken]);

  const handleWorkingHourEditClick = () => {
    setIsEditingWorkingHour(!isEditingWorkingHour);
  };

  
  const convertToFrontendFormat = (time) => {
    let formattedTime = null;
    if (time != null) {
      formattedTime = moment(time, "HH:mm:ss").format("hh:mm A");
    }
    return formattedTime;
  };
  const handleWorkingHourSaveClick = () => {

    const axiosConfig = {
      headers: {
        ...authHeader(),
      },
    };

    axios
      .post(API_URL + "api/owner/add-working-hours", workingHours, axiosConfig)
      .then((response) => {
        axios
          .get(API_URL + "api/owner/gym-working-hours", axiosConfig)
          .then((response) => {
            setWorkingHours(response.data.responseData.data.workingHours);
            setIsEditingWorkingHour(false);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      })
      .catch((error) => {
        console.error("Error creating/updating team:", error);
      });
  };

  const handleTimeChange = (key, value) => {
    setWorkingHours((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  return (
    <>
      <div className="col-span-2 sm:col-span-1 bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="bg-white sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6 row">
            <div className="col">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Working Hour
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Details and informations about gym working hours.
              </p>
            </div>
            {isEditingWorkingHour ? (
              <div>
                <button
                  onClick={handleWorkingHourEditClick}
                  className="text-emerald-600 border border-emerald-300 px-3 py-2 rounded-lg shadow-lg text-sm"
                >
                  Cancel
                </button>
                <button
                  onClick={handleWorkingHourSaveClick}
                  className="bg-emerald-600 text-gray-100 px-3 py-2 rounded-lg shadow-lg text-sm ml-2"
                >
                  Save
                </button>
              </div>
            ) : (
              <button
                onClick={handleWorkingHourEditClick}
                className="bg-emerald-600 text-gray-100 px-3 py-2 rounded-lg shadow-lg text-sm"
              >
                Edit
              </button>
            )}
          </div>
        </div>
        {isEditingWorkingHour ? (
          // Edit mode
          <form>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6 items-center">
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  Monday - Saturday
                </dt>
              </div>
              <div className="sm:col-span-3">
                <label>Morning :</label>
                <div className="space-x-2 mt-2">
                  <label htmlFor="morningOpeningTime">Opening Time:</label>
                  <input
                    type="time"
                    name="morningOpeningTime"
                    className="inputs time"
                    required
                    value={workingHours.morning.start}
                    onChange={(e) =>
                      handleTimeChange("morning", {
                        ...workingHours.morning,
                        start: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="space-x-1 mt-1 mb-2">
                  <label htmlFor="morningClosingTime">Closing Time:</label>
                  <input
                    type="time"
                    name="morningClosingTime"
                    className="inputs time"
                    required
                    value={workingHours.morning.end}
                    onChange={(e) =>
                      handleTimeChange("morning", {
                        ...workingHours.morning,
                        end: e.target.value,
                      })
                    }
                  />
                </div>
                <label className="mt-2">Evening :</label>
                <div className="space-x-1 mt-2">
                  <label htmlFor="eveningOpeningTime">Opening Time:</label>
                  <input
                    type="time"
                    name="eveningOpeningTime"
                    className="inputs time"
                    required
                    value={workingHours.evening.start}
                    onChange={(e) =>
                      handleTimeChange("evening", {
                        ...workingHours.evening,
                        start: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="space-x-2 mt-1">
                  <label htmlFor="eveningClosingTime">Closing Time:</label>
                  <input
                    type="time"
                    name="eveningClosingTime"
                    className="inputs time"
                    required
                    value={workingHours.evening.end}
                    onChange={(e) =>
                      handleTimeChange("evening", {
                        ...workingHours.evening,
                        end: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </form>
        ) : (
          // Display mode
          <div>
            <div
              className={`bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6 items-center`}
            >
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  Monday - Saturday
                </dt>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-900">
                  {workingHours?.morning?.start &&
                  workingHours?.morning?.end ? (
                    <>
                      Morning:{" "}
                      {convertToFrontendFormat(workingHours?.morning?.start)} to{" "}
                      {convertToFrontendFormat(workingHours?.morning?.end)}
                    </>
                  ) : (
                    "Not Assigned"
                  )}
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {workingHours?.evening?.start &&
                  workingHours?.evening?.end ? (
                    <>
                      Evening:{" "}
                      {convertToFrontendFormat(workingHours?.evening?.start)} to{" "}
                      {convertToFrontendFormat(workingHours?.evening?.end)}
                    </>
                  ) : (
                    ""
                  )}
                </dd>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default WorkingHourTable;
