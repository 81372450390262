import React, { useEffect, useState } from "react";
import { Row, Container } from "react-bootstrap";
import axios from "axios";

function AboutUs({ url }) {
  const API_URL = process.env.REACT_APP_ADMIN_API_URL;
  const [aboutData, setAboutData] = useState({
    ourMission: "",
    ourHistory: "",
    founderStory: "",
    trainerStory: "",
  });
  const aboutDetailsUrl = `${API_URL}api/owner/page-about-gym?url=${url}`;
  useEffect(() => {
    axios
      .get(aboutDetailsUrl)
      .then((response) => {
        setAboutData(response.data.responseData.data);
      })
      .catch((error) => {
        console.error("Error fetching basic details data:", error);
      });
  }, [aboutDetailsUrl]);
  return (
    <section id="about-us">
      <Container>
        <Row>
          <div className="section-image-features col-30">
            <img src="../images/fitness-gym-girl-nb.jpg" width="200" alt="features" />
          </div>
          <div className="section-text col-12">
            <div className="section-text__title">About Us</div>
            <div className="row">
              <div className="feature-box col-50">
                <div className="section-text__title-small">Our Mission</div>
                <div className="section-text__desc">
                  {aboutData.ourMission
                    ? aboutData.ourMission
                    : "Your mission statement goes here. Explain what sets your gym apart and how it benefits your members."}
                </div>
              </div>
              <div className="feature-box col-50">
                <div className="section-text__title-small">Our History</div>
                <div className="section-text__desc">
                  {aboutData.ourHistory
                    ? aboutData.ourHistory
                    : "Share the history of your gym, when it was established, and any significant milestones."}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="feature-box col-50">
                <div className="section-text__title-small">Founder Story</div>
                <div className="section-text__desc">
                  {aboutData.founderStory
                    ? aboutData.founderStory
                    : "If applicable, share the inspiring story of your gym's founder and their motivation behind starting the gym."}
                </div>
              </div>

              <div className="feature-box col-50">
                <div className="section-text__title-small">Trainer Story</div>
                <div className="section-text__desc">
                  {aboutData.trainerStory
                    ? aboutData.trainerStory
                    : "If applicable, share the inspiring story of your gym's trainer and their motivation behind doing the gym as profession."}
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </section>
  );
}

export default AboutUs;
