import axios from "axios";

const API_URL = process.env.REACT_APP_AUTH_API_URL;

const register = ( name, email,gymName, gymAddress,pincode, password) => {
  return axios.post(API_URL + "api/auth/gym-owner-signup", {
    name,
    email,
    gymName,
    gymAddress,
    pincode,
    password,
  }).then((response)=>{
    return response.data;
  });
};

const login = (username, password) => {
  return axios
    .post(API_URL + "signin", {
      username,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const logout = () => {
  try {
    localStorage.removeItem("user");
  } catch (error) {
    console.error("Error removing user from localStorage:", error);
  }
};

const authModule = {
  register,
  login,
  logout,
};

export default authModule;