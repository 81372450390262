import React, { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

const ActionModal = ({ isOpen, onClose, feedbackId, refresh }) => {
  const API_URL = process.env.REACT_APP_ADMIN_API_URL;
  const { user: currentUser } = useSelector((state) => state.auth);
  const accessToken = currentUser ? currentUser.accessToken : null;
  const [actionTaken, setActionTaken] = useState("");
  const [showActionMsgError, setActionMsgError] = useState("");

  const handleOnClose = () => {
    onClose();
    setActionTaken("");
  }
  const handleConfirmAction = () => {
    if (actionTaken.trim() === "") {
      setActionMsgError("Action cannot be kept blank");
      return;
    }
  
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const data ={
      actionTaken: actionTaken
    }
    axios
      .put(`${API_URL}/${feedbackId}/set-action`, data, axiosConfig)
      .then((response) => {
        setActionTaken("");
        refresh();
        onClose();
      })
      .catch((error) => {
        console.error("Error setting action:", error);
      });
  };  

  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center ${
        isOpen ? "" : "hidden"
      }`}
    >
      <div className="fixed inset-0 bg-black opacity-40"></div>
      <div className="relative bg-white rounded-lg shadow-lg max-w-md w-full p-6">
        <textarea
          className="w-full h-40 p-2 border rounded-md resize-none my-2"
          placeholder="Write your reply..."
          value={actionTaken}
          onChange={(e) => setActionTaken(e.target.value)}
          style={{ width: "100%" }}
        ></textarea>
        <span className="text-red-600 text-sm">{showActionMsgError}</span>
        <div className="mt-4 flex justify-end">
          <button
            className="px-4 py-2 bg-gray-300 rounded-md mr-4"
            onClick={handleOnClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-emerald-500 text-white rounded-md"
            onClick={handleConfirmAction}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

export default ActionModal;
