import React from "react";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { authHeader } from "../../../services/auth-header";
function GymBasicDetails({ accessToken }) {
  const API_URL = process.env.REACT_APP_ADMIN_API_URL;
  const [isEditingBasicDetails, setIsEditingBasicDetails] = useState(false);
  const [basicDetailsData, setBasicDetailsData] = useState({
    gym_name: "",
    email: "",
    mobile: "",
    gym_address: "",
    title: "",
    body: "",
    instaUrl: "",
    facebookUrl: "",
    url: "",
    urlAvailable: null,
    urlTouched: false
  });
  useEffect(() => {
    if (accessToken) {
      const axiosConfig = {
        headers: {
          ...authHeader(),
        },
      };
      axios
        .get(API_URL + "api/owner/basic-details", axiosConfig)
        .then((response) => {
          setBasicDetailsData(response.data.responseData.data);
        })
        .catch((error) => {
          console.error("Error fetching basic details data:", error);
        });
    } else {
      console.error("Access token not found in localStorage");
    }
  }, [API_URL, accessToken]);
  const handleBasicDetailsEditClick = () => {
    setIsEditingBasicDetails(!isEditingBasicDetails);
  };

  const handleBasicDetailsSaveClick = () => {
    if (basicDetailsData.url) {
      if (basicDetailsData.urlAvailable === true) {
        saveBasicDetails();
      } else {
        console.log("URL is present but URL availability is false. Cannot save basic details.");
      }
    } else {
      console.log("URL is null. Saving basic details without checking URL availability.");
      saveBasicDetails();
    }
  };
  const saveBasicDetails = () => {
    const requestBody = {
      gym_name: basicDetailsData.gym_name,
      email: basicDetailsData.email,
      mobile: basicDetailsData.mobile,
      gym_address: basicDetailsData.gym_address,
      title: basicDetailsData.title,
      body: basicDetailsData.body,
      instaUrl: basicDetailsData.instaUrl,
      facebookUrl: basicDetailsData.facebookUrl,
      url: basicDetailsData.url,
    };
  
    const axiosConfig = {
      headers: {
        ...authHeader(),
      },
    };
  
    axios
      .post(API_URL + "api/owner/add-basic-details", requestBody, axiosConfig)
      .then((response) => {
        axios
          .get(API_URL + "api/owner/basic-details", axiosConfig)
          .then((response) => {
            setBasicDetailsData(response.data.responseData.data);
            if(response.data.responseData.data.url){
              localStorage.setItem("url",response.data.responseData.data.url )
            }
            setIsEditingBasicDetails(false);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      })
      .catch((error) => {
        console.error("Error updating basic details:", error);
      });
  };
  const handleBasicDetailsInputChange = (e) => {
    const { name, value } = e.target;
    setBasicDetailsData((prevState) => ({
      ...prevState,
      [name]: value,
      urlTouched: true
    }));

    if (name === "url") {
      checkAvailability(value);
    }
  };
  const checkAvailability = async (url) => {
    try {
      const axiosConfig = {
        headers: {
          ...authHeader(),
        },
      };
      const response = await axios.get(
        API_URL + `api/owner/check-availability?url=${url}`,
        axiosConfig
      );
      if (response.data.responseData.success) {
        console.log("URL is available");
        setBasicDetailsData((prevState) => ({
          ...prevState,
          urlAvailable: response.data.responseData.success,
        }));
      } else {
        console.log("URL is not available");
        setBasicDetailsData((prevState) => ({
          ...prevState,
          urlAvailable: response.data.responseData.success,
        }));
      }
    } catch (error) {
      console.error("Error checking URL availability:", error);
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === ' ') {
      e.preventDefault();
    }
  };
  return (
    <>
      <div className="col-span-2 sm:col-span-1 bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="bg-white sm:rounded-lg">
          <div class="px-4 py-5 sm:px-6 row">
            <div className="col">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Basic Details
              </h3>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                Details and informations about gym basic details.
              </p>
            </div>
            {isEditingBasicDetails ? (
              <div>
                <button
                  onClick={handleBasicDetailsEditClick}
                  className="text-emerald-600 border border-emerald-300 px-3 py-2 rounded-lg shadow-lg text-sm"
                >
                  Cancel
                </button>
                <button
                  onClick={handleBasicDetailsSaveClick}
                  className="bg-emerald-600 text-gray-100 px-3 py-2 rounded-lg shadow-lg text-sm ml-2"
                >
                  Save
                </button>
              </div>
            ) : (
              <button
                onClick={handleBasicDetailsEditClick}
                className="bg-emerald-600 text-gray-100 px-3 py-2 rounded-lg shadow-lg text-sm"
              >
                Edit
              </button>
            )}
          </div>
        </div>

        {isEditingBasicDetails ? (
          // Edit mode
          <form>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Name</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="gym_name"
                  value={basicDetailsData.gym_name}
                  onChange={handleBasicDetailsInputChange}
                  className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                  placeholder="Gym Name"
                />
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Email</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="email"
                  value={basicDetailsData.email}
                  onChange={handleBasicDetailsInputChange}
                  className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                  placeholder="Email"
                />
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Mobile</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <input
                  type="number"
                  name="mobile"
                  value={basicDetailsData.mobile}
                  onChange={handleBasicDetailsInputChange}
                  className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                  placeholder="Mobile"
                />
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 relative">
              <dt className="text-sm font-medium text-gray-500">Gym Url</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center relative">
                <span className="text-sm">syncwell.com/gym/</span>
                <input
                  type="text"
                  name="url"
                  value={basicDetailsData.url}
                  onChange={handleBasicDetailsInputChange}
                  onKeyDown={handleKeyDown}
                  className="border border-gray-200 focus:border-emerald-400 focus:outline-none mx-2 placeholder-gray-500 px-2 py-2 rounded-lg text-sm"
                  placeholder="URL"
                  pattern="^\S+$"
                />
                {basicDetailsData.urlTouched && (
                  <span
                    className={`absolute bottom-0 right-0 text-sm ${
                      basicDetailsData.urlAvailable === true
                        ? "text-green-500"
                        : "text-red-500"
                    }`}
                    style={{ transform: "translateY(calc(100% + 5px))" }}
                  >
                    {basicDetailsData.urlAvailable === true
                      ? "URL is available"
                      : "URL is not available"}
                  </span>
                )}
              </dd>
            </div>

            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Instagram Link
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="instaUrl"
                  value={basicDetailsData.instaUrl}
                  onChange={handleBasicDetailsInputChange}
                  className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                  placeholder="https://www.instagram.com"
                />
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Facebook Link
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="facebookUrl"
                  value={basicDetailsData.facebookUrl}
                  onChange={handleBasicDetailsInputChange}
                  className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                  placeholder="https://www.facebook.com"
                />
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Address</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="address"
                  value={basicDetailsData.gym_address}
                  onChange={handleBasicDetailsInputChange}
                  className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                  placeholder="Address"
                />
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Welcome Title
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="title"
                  value={basicDetailsData.title}
                  onChange={handleBasicDetailsInputChange}
                  className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                  placeholder="Welcome Title"
                />
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Welcome Body
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <textarea
                  type="text"
                  name="body"
                  value={basicDetailsData.body}
                  onChange={handleBasicDetailsInputChange}
                  className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                  placeholder="Welcome Body"
                />
              </dd>
            </div>
          </form>
        ) : (
          // Display mode
          <div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Gym Name</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {basicDetailsData.gym_name}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Email</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {basicDetailsData.email}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Mobile</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {basicDetailsData.mobile
                  ? basicDetailsData.mobile
                  : "Please provide your mobile no by clicking edit button"}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Gym Url</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {basicDetailsData.url
                  ? basicDetailsData.url
                  : "Please set your gym url by clicking edit button"}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Instagram Link
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {basicDetailsData.instaUrl
                  ? basicDetailsData.instaUrl
                  : "Please provide your instagram url by clicking edit button"}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Facebook Link
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {basicDetailsData.facebookUrl
                  ? basicDetailsData.facebookUrl
                  : "Please provide your facebook url by clicking edit button"}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Gym Address</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {basicDetailsData.gym_address}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Title</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {basicDetailsData.title
                  ? basicDetailsData.title
                  : "Please provide your gym title by clicking edit button"}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Body</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {basicDetailsData.body
                  ? basicDetailsData.body
                  : "Please provide your gym body by clicking edit button"}
              </dd>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default GymBasicDetails;
