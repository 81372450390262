import React from "react";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { authHeader } from "../../../services/auth-header";
function AboutGym({ accessToken }) {
  const API_URL = process.env.REACT_APP_ADMIN_API_URL;

  const [aboutData, setAboutData] = useState({
    ourMission: "",
    ourHistory: "",
    founderStory: "",
    trainerStory: "",
  });
  const [isEditingAbout, setIsEditingAbout] = useState(false);

  useEffect(() => {
    if (accessToken) {
      const axiosConfig = {
        headers: {
          ...authHeader(),
        },
      };
      axios
        .get(API_URL + "api/owner/about-details", axiosConfig)
        .then((response) => {
          setAboutData(response.data.responseData.data);
        })
        .catch((error) => {
          console.error("Error fetching basic details data:", error);
        });
    } else {
      console.error("Access token not found in localStorage");
    }
  }, [API_URL,accessToken]);

  const handleAboutEditClick = () => {
    setIsEditingAbout(!isEditingAbout);
  };

  const handleAboutSaveClick = () => {
    const requestBody = {
      ourMission: aboutData.ourMission,
      ourHistory: aboutData.ourHistory,
      founderStory: aboutData.founderStory,
      trainerStory: aboutData.trainerStory,
    };

    const axiosConfig = {
      headers: {
        ...authHeader(),
      },
    };

    axios
      .post(API_URL + "api/owner/add-about-details", requestBody, axiosConfig)
      .then((response) => {
        axios
          .get(API_URL + "api/owner/about-details", axiosConfig)
          .then((response) => {
            setAboutData(response.data.responseData.data);
            setIsEditingAbout(false);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      })
      .catch((error) => {
        console.error("Error updating basic details:", error);
      });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAboutData({
      ...aboutData,
      [name]: value,
    });
  };
  return (
    <>
      <div className="col-span-2 sm:col-span-1 bg-white shadow overflow-hidden sm:rounded-lg">
        <div class="px-4 py-5 sm:px-6 row">
          <div className="col">
            <h3 class="text-lg leading-6 font-medium text-gray-900">About</h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">
              Details and informations about gym.
            </p>
          </div>
          {isEditingAbout ? (
            <div>
              <button
                onClick={handleAboutEditClick}
                className="text-emerald-600 border border-emerald-300 px-3 py-2 rounded-lg shadow-lg text-sm"
              >
                Cancel
              </button>
              <button
                onClick={handleAboutSaveClick}
                className="bg-emerald-600 text-gray-100 px-3 py-2 rounded-lg shadow-lg text-sm ml-2"
              >
                Save
              </button>
            </div>
          ) : (
            <button
              onClick={handleAboutEditClick}
              className="bg-emerald-600 text-gray-100 px-3 py-2 rounded-lg shadow-lg text-sm"
            >
              Edit
            </button>
          )}
        </div>
        {isEditingAbout ? (
          // Edit mode
          <form>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Our Mission</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <textarea
                  type="text"
                  name="ourMission"
                  value={aboutData?.ourMission}
                  onChange={handleChange}
                  className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                  placeholder="Our Mission"
                />
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Our History</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <textarea
                  type="text"
                  name="ourHistory"
                  value={aboutData?.ourHistory}
                  onChange={handleChange}
                  className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                  placeholder="Our History"
                />
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Founder Story
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <textarea
                  type="text"
                  name="founderStory"
                  value={aboutData?.founderStory}
                  onChange={handleChange}
                  className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                  placeholder="Founder Story"
                />
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Trainer Story
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <textarea
                  type="text"
                  name="trainerStory"
                  value={aboutData?.trainerStory}
                  onChange={handleChange}
                  className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                  placeholder="Trainer Story"
                />
              </dd>
            </div>
          </form>
        ) : (
          <div>
            <div>
              <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">Our Mission</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {aboutData?.ourMission}
                </dd>
              </div>
              <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">Our History</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {aboutData?.ourHistory}
                </dd>
              </div>
              <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">Founder Story</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {aboutData?.founderStory}
                </dd>
              </div>
              <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">Trainer Story</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {aboutData?.trainerStory}
                </dd>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default AboutGym;
