import React from "react";
import { Link } from "react-router-dom";
import Datatables from "../components/Datatables/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRemove } from "@fortawesome/free-solid-svg-icons";
import DietTableCell from "../components/Datatables/DietTableCell";

function DietPlanTable({ loading, dataHeader, data, handleDelete }) {
  return (
    <Datatables loading={loading} dataHeader={dataHeader}>
      {data?.map((row, index) => (
        <tr
          key={index}
          className="bg-white border md:border-b block md:table-row rounded-md shadow-md md:rounded-none md:shadow-none mb-5"
        >
          <DietTableCell dataLabel="Name" showLabel={true}>
            <span className="font-medium text-sm text-gray-900">
              {row.plan_name}
            </span>
          </DietTableCell>
          <DietTableCell dataLabel="Description" showLabel={true}>
            <p className="font-normal text-sm text-gray-500">
              {row.description}
            </p>
          </DietTableCell>
          {row.protein_sources?.length > 0 ? (
            <DietTableCell dataLabel="Protien Sources" showLabel={true}>
              {/* Render the protein sources */}
              <p className="font-normal text-sm text-gray-500">
                {row.protein_sources.join(", ")}{" "}
                {/* Join protein sources with comma and space */}
              </p>
            </DietTableCell>
          ) : (
            <DietTableCell dataLabel="Protien Sources" showLabel={true}>
              {/* Render the protein sources */}
              <p className="font-normal text-sm text-gray-500">
                In this plan this nutrition source is not given.
              </p>
            </DietTableCell>
          )}

          {row.carb_sources?.length > 0 ? (
            <DietTableCell dataLabel="Carb Sources" showLabel={true}>
              {/* Render the carbohydrate sources */}
              <p className="font-normal text-sm text-gray-500">
                {row.carb_sources.join(", ")}{" "}
                {/* Join carbohydrate sources with comma and space */}
              </p>
            </DietTableCell>
          ): <DietTableCell dataLabel="Carb Sources" showLabel={true}>
          {/* Render the carbohydrate sources */}
          <p className="font-normal text-sm text-gray-500">
          In this plan this nutrition source is not given.
          </p>
        </DietTableCell>}

          {row.fat_sources?.length > 0 ? (
            <DietTableCell dataLabel="Fat Sources" showLabel={true}>
              {/* Render the fat sources */}
              <p className="font-normal text-sm text-gray-500">
                {row.fat_sources.join(", ")}{" "}
                {/* Join fat sources with comma and space */}
              </p>
            </DietTableCell>
          ): <DietTableCell dataLabel="Fat Sources" showLabel={true}>
          {/* Render the fat sources */}
          <p className="font-normal text-sm text-gray-500">
          In this plan this nutrition source is not given.
          </p>
        </DietTableCell>}

          <DietTableCell dataLabel="Additional Info" showLabel={true}>
            <p className="font-normal text-sm text-gray-500">
              {row.additional_info}
            </p>
          </DietTableCell>
          {/* <DietTableCell dataLabel="Role" showLabel={true}>
            <span className=" space-x-1">
              {row.roles?.map((role, index) => (
                <span
                  key={index}
                  className="rounded-full py-1 px-3 text-xs font-semibold bg-emerald-200 text-green-900"
                >
                  {role.name}
                </span>
              ))}
            </span>
          </DietTableCell> */}
          <DietTableCell>
            {/* Your Edit Link */}
            {/* <Link
    to={`/auth/master/user/${row.id}/edit`}
    className={`text-sky-700 inline-flex py-2 px-2 rounded text-sm`}
  >
    <FontAwesomeIcon icon={faPencil} />
  </Link> */}

            {/* Conditionally render the Delete Link */}
            {row.commonPlan ? (
              <span className="text-gray-500 text-sm">
                No Action for Common Plan
              </span>
            ) : (
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  handleDelete(row._id);
                }}
                to={`/auth/master/user/${row.id}/edit`}
                className={`text-red-700 inline-flex py-2 px-2 rounded text-sm`}
              >
                <FontAwesomeIcon icon={faRemove} />
              </Link>
            )}
          </DietTableCell>
        </tr>
      ))}
    </Datatables>
  );
}

export default DietPlanTable;
