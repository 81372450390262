import React from "react";
import { Link } from "react-router-dom";
import Datatables from "../components/Datatables/Table";
import TableCell from "../components/Datatables/TableCell";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRemove } from "@fortawesome/free-solid-svg-icons";

function MemberTable({ loading, dataHeader, data, handleDelete, handleUserId}) {
  const calculateDaysLeft = (expirationDate) => {
    const currentDate = new Date();
    const expiration = new Date(expirationDate);
    const timeDifference = expiration - currentDate;
    const daysLeft = Math.ceil(timeDifference / (1000 * 3600 * 24));
    return daysLeft;
  };
  const handleRenew = (userId) => {
   handleUserId(userId);
  };
  return (
    <Datatables loading={loading} dataHeader={dataHeader}>
      {data.map((row, index) => (
        <tr
          key={index}
          className="bg-white border md:border-b block md:table-row rounded-md shadow-md md:rounded-none md:shadow-none mb-5"
        >
          <TableCell dataLabel="Name" showLabel={true}>
            <span className="font-medium text-sm text-gray-900">
              {row.name}
            </span>
          </TableCell>
          <TableCell dataLabel="Username" showLabel={true}>
            <p className="font-normal text-sm text-gray-500">{row.mobile}</p>
          </TableCell>
          <TableCell dataLabel="Email" showLabel={true}>
            <p className="font-normal text-sm text-gray-500">{row.email}</p>
          </TableCell>
          <TableCell dataLabel="Plan Name" showLabel={true}>
            <p className="font-normal text-sm text-gray-500">
              <span
                key={index}
                className="rounded-full py-1 px-3 text-xs font-semibold bg-emerald-200 text-green-900"
              >
                {row.plan.plan_name}
              </span>
            </p>
          </TableCell>
          { row.diet_plan.plan_name!=null ? (<TableCell dataLabel="Diet Plan Name" showLabel={true}>
            <p className="font-normal text-sm text-gray-500">
                {row.diet_plan.plan_name}
            </p>
          </TableCell>) : (<TableCell dataLabel="Diet Plan Name" showLabel={true}>
            <p className="font-normal text-sm text-gray-500">
                Not Assigned
            </p>
          </TableCell>) }
          <TableCell dataLabel="Days Left" showLabel={true}>
            <p className="font-normal text-sm text-gray-500">
            {calculateDaysLeft(row.plan_expiration) < 1 ? "Plan Expired" : `${calculateDaysLeft(row.plan_expiration)} ${calculateDaysLeft(row.plan_expiration) === 1 ? "day" : "days"}`}
            </p>
          </TableCell>
          <TableCell>
          {calculateDaysLeft(row.plan_expiration) < 1 && (
          <button
            onClick={() => handleRenew(row._id)}
            className="bg-emerald-600 text-gray-100 p-1 rounded-lg shadow-lg text-sm"
          >
            Renew Plan
          </button>
        )}
            {/* <Link
              to={`/auth/master/user/${row.id}/edit`}
              className={`text-sky-700 inline-flex py-2 px-2 rounded  text-sm`}
            >
              <FontAwesomeIcon icon={faPencil} />
            </Link> */}
            <Link
              onClick={(e) => {
                e.preventDefault();
                handleDelete(row._id);
              }}
              to={`/auth/master/user/${row._id}/edit`}
              className={`text-red-700 inline-flex py-2 px-2 rounded  text-sm`}
            >
              <FontAwesomeIcon icon={faRemove} />
            </Link>
          </TableCell>
        </tr>
      ))}
    </Datatables>
  );
}

export default MemberTable;
