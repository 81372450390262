import { faPage4 } from "@fortawesome/free-brands-svg-icons";
import {
  faTachometer,
  faTableColumns,
  faUser,
  faCalendarDay,
  faFileImage,
  faFeed
} from "@fortawesome/free-solid-svg-icons";
const url = localStorage.getItem('url');

const initMenu = [
  {
    label: "Dashboard",
    path: "/admin/dashboard",
    icon: faTachometer,
  },
  {
    label: 'Admin'
  },
  {
    label: "Members",
    path: "/members",
    icon: faUser,
  },
  {
    label: "Plans",
    path: "/plans",
    icon: faTableColumns
  },
  {
    label: "Diet Plans",
    path: "/diet/plans",
    icon: faCalendarDay,
  },
  {
    label: "Gym Details",
    path: "/gym/details",
    icon: faPage4,
  },
  {
    label: "Feedback",
    path: "/gym/feedback",
    icon: faFeed,
  },
  {
    label: 'User View'
  },
  {
    label: "Gym Page",
    path: url ? `/gym/${url}` : "/gym/not-assigned",
    icon:faFileImage
  },
];

export default initMenu