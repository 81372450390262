import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar/Index";
import { useOutletContext } from "react-router-dom";
import axios from "axios";
import MemberTable from "./MemberTable";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmationModal from "../components/ConfirmationModal";
import RenewModal from "../components/RenewModal";
import { authHeader } from "../services/auth-header";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
function Member() {
  const API_URL = process.env.REACT_APP_ADMIN_API_URL;
  const AUTH_API_URL = process.env.REACT_APP_AUTH_API_URL;
  const userData = localStorage.getItem("token");
  const accessToken = userData ? userData : null;
  const [data, setData] = useState([]);
  const [sidebarToggle] = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [showExisting, setShowExisting] = useState(false);
  const [plans, setPlans] = useState([]);
  const [dietPlans, setDietPlans] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState(null);
  const [newMember, setNewMember] = useState({
    name: "",
    mobile: "",
    email: "",
    password: "",
    membershipPlan: "",
    dietPlan: "",
  });
  const [nameError, setNameError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [planError, setPlanError] = useState("");
  const [dateError, setDateError] = useState("");
  const [searchText, setSearchText] = useState("");
  const [messageResponse, setMessageResponse] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [userId, setUserId] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedButton, setSelectedButton] = useState(null);
  const msg = "Are you sure you want to delete this member?";

  useEffect(() => {
    let apiUrl = `${API_URL}api/owner/members?pageNo=${currentPage}&pageSize=10`;
    if (searchText) {
      apiUrl += `&searchText=${searchText}`;
    }
    const config = {
      headers: {
        ...authHeader(),
      },
    };
    axios
      .get(apiUrl, config)
      .then((response) => {
        setData(response.data.responseData.data);
        setTotalPages(response.data.responseData.totalPages);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });

    axios
      .get(API_URL + "api/owner/plans", config)
      .then((response) => {
        setPlans(response.data.responseData.data);
      })
      .catch((error) => {
        console.error("Error fetching plans:", error);
      });

    axios
      .get(API_URL + "api/owner/diet-plans", config)
      .then((response) => {
        setDietPlans(response.data.responseData.data);
      })
      .catch((error) => {
        console.error("Error fetching diet plans:", error);
      });
  }, [API_URL, accessToken, currentPage, searchText]);

  if (!userData) {
    localStorage.removeItem("token");
    window.location.href = "/auth/login";
  }

  const dataHeader = [
    {
      key: "name",
      label: "Name",
    },
    {
      key: "mobile",
      label: "Mobile",
    },
    {
      key: "email",
      label: "Email",
    },
    {
      key: "plan",
      label: "Plan",
    },
    {
      key: "dietPlan",
      label: "Diet Plan",
    },
    {
      key: "daysLeft",
      label: "Days Left",
    },
    {
      key: "action",
      label: "Action",
    },
  ];

  const isEmailValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
    newMember.email
  );

  const validateForm = () => {
    let valid = true;

    if (newMember.name.trim() === "") {
      setNameError("Name is required");
      valid = false;
    }

    if (newMember.mobile.trim() === "") {
      setMobileError("Mobile is required");
      valid = false;
    }

    if (!isEmailValid) {
      setEmailError("Please enter a valid email address");
      valid = false;
    }

    if (newMember.password.length < 6) {
      setPasswordError("Password must be at least 6 characters");
      valid = false;
    }

    if (showDate) {
      if (!newMember.date) {
        setDateError("Date is required");
        valid = false;
      }
    }

    if (newMember.membershipPlan === "") {
      setPlanError("Plan is required");
      valid = false;
    }

    return valid;
  };
  const handleSearch = (value) => {
    setSearchText(value);
  };
  const handleAddMember = () => {
    setNameError("");
    setMobileError("");
    setDateError("");
    setEmailError("");
    setPasswordError("");
    setPlanError("");

    if (validateForm()) {
      const axiosConfig = {
        headers: {
          ...authHeader(),
        },
      };

      const newMemberData = {
        mobile: newMember.mobile,
        password: newMember.password,
        name: newMember.name,
        email: newMember.email,
        date: newMember.date,
        startDate: newMember.startDate,
        endDate: newMember.endDate,
        membershipPlan: newMember.membershipPlan,
        dietPlan: newMember.dietPlan,
      };

      axios
        .post(AUTH_API_URL + "api/owner/add-member", newMemberData, axiosConfig)
        .then((response) => {
          setShowSuccessMessage(true);

          setTimeout(() => {
            setShowSuccessMessage(false);
          }, 3000);
          axios
            .get(
              API_URL + `api/owner/members?page=${currentPage}&size=10`,
              axiosConfig
            )
            .then((response) => {
              setData(response.data.responseData.data);
              setNewMember({
                name: "",
                mobile: "",
                email: "",
                password: "",
                membershipPlan: "",
                dietPlan: "",
              });
              setShow(false);
              setMessageResponse("");
            })
            .catch((error) => {
              console.error("Error fetching updated data:", error);
            });
        })
        .catch((error) => {
          console.error("Error creating member:", error);
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            setMessageResponse(error.response.data.message);
          }
        });
    }
  };
  const handleRenew = (userId) => {
    setModalOpen(true);
    setUserId(userId);
  };
  const handleDelete = (userId) => {
    setDeleteModalOpen(true);
    setMemberToDelete(userId);
  };
  const handleConfirmDelete = () => {
    setDeleteModalOpen(false);
    const axiosConfig = {
      headers: {
        ...authHeader(),
      },
    };
    axios
      .delete(API_URL + `api/owner/delete-member/?id=${memberToDelete}`, axiosConfig)
      .then((response) => {
        const updatedData = data.filter((user) => user._id !== memberToDelete);
        setShowDeleteMessage(true);

        setTimeout(() => {
          setShowDeleteMessage(false);
        }, 3000);
        setData(updatedData);
      })
      .catch((error) => {
        console.error("Error deleting plan:", error);
      });
  };
  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };
  const handleRefresh = () => {
    let apiUrl = `${API_URL}/gym-members?page=${currentPage}&size=10`;
    if (accessToken) {
      const axiosConfig = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      axios
        .get(apiUrl, axiosConfig)
        .then((response) => {
          setData(response.data.content);
          setTotalPages(response.data.totalPages);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    } else {
      console.error("Access token not found in localStorage");
    }
  };
  const handleButtonClick = (buttonId) => {
    if (selectedButton === buttonId) {
      setShow(false);
      setShowDate(false);
      setShowExisting(false);
      setSelectedButton(null);
    } else {
      setShow(buttonId === 1);
      setShowDate(buttonId === 2);
      setShowExisting(buttonId === 3);
      setSelectedButton(buttonId);
    }
  };
  return (
    <>
      <main className="h-full">
        <Navbar toggle={sidebarToggle} />
        <ConfirmationModal
          isOpen={isDeleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={handleConfirmDelete}
          msg={msg}
        />
        <RenewModal
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          plans={plans}
          userId={userId}
          refresh={handleRefresh}
        />
        <div className="mainCard">
          <div className="border w-full border-gray-200 bg-white py-4 px-6 rounded-md">
            <button
              className={`bg-emerald-600 text-gray-100 px-3 py-2 rounded-lg shadow-lg text-sm ${
                selectedButton === 1
                  ? "opacity-100"
                  : selectedButton
                  ? "opacity-50"
                  : ""
              }`}
              onClick={() => handleButtonClick(1)}
            >
              Add New Member using Plan
            </button>
            <button
              className={`bg-emerald-600 text-gray-100 px-3 py-2 rounded-lg shadow-lg text-sm mx-2 ${
                selectedButton === 2
                  ? "opacity-100"
                  : selectedButton
                  ? "opacity-50"
                  : ""
              }`}
              onClick={() => handleButtonClick(2)}
            >
              Add New Member using Date
            </button>
            <button
              className={`bg-emerald-600 text-gray-100 px-3 py-2 rounded-lg shadow-lg text-sm mx-1 ${
                selectedButton === 3
                  ? "opacity-100"
                  : selectedButton
                  ? "opacity-50"
                  : ""
              }`}
              onClick={() => handleButtonClick(3)}
            >
              Add Existing Member
            </button>
            {show && (
              <form className="flex flex-wrap justify-end mt-4">
                {/* First Row */}
                <div className="w-full md:w-1/3 px-2">
                  <label htmlFor="name" className="text-sm text-gray-600">
                    Name
                  </label>
                  <input
                    id="name"
                    type="text"
                    name="name"
                    value={newMember.name}
                    onChange={(e) =>
                      setNewMember({ ...newMember, name: e.target.value })
                    }
                    className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                    placeholder="Name"
                  />
                  <span className="text-red-600 text-sm">{nameError}</span>
                </div>

                <div className="w-full md:w-1/3 px-2">
                  <label htmlFor="mobile" className="text-sm text-gray-600">
                    Mobile
                  </label>
                  <input
                    id="mobile"
                    type="number"
                    name="mobile"
                    value={newMember.mobile}
                    onChange={(e) =>
                      setNewMember({ ...newMember, mobile: e.target.value })
                    }
                    className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                    placeholder="Mobile"
                  />
                  <span className="text-red-600 text-sm">{mobileError}</span>
                </div>

                <div className="w-full md:w-1/3 px-2">
                  <label htmlFor="email" className="text-sm text-gray-600">
                    Email
                  </label>
                  <input
                    id="email"
                    type="email"
                    name="email"
                    value={newMember.email}
                    onChange={(e) =>
                      setNewMember({ ...newMember, email: e.target.value })
                    }
                    className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                    placeholder="Email"
                  />
                  <span className="text-red-600 text-sm">{emailError}</span>
                </div>

                {/* Second Row */}
                <div className="w-full md:w-1/3 px-2">
                  <label htmlFor="password" className="text-sm text-gray-600">
                    Password
                  </label>
                  <input
                    id="password"
                    type="text"
                    name="password"
                    value={newMember.password}
                    onChange={(e) =>
                      setNewMember({ ...newMember, password: e.target.value })
                    }
                    className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                    placeholder="Password"
                  />
                  <span className="text-red-600 text-sm">{passwordError}</span>
                </div>

                <div className="w-full md:w-1/3 px-2">
                  <label htmlFor="plan" className="text-sm text-gray-600">
                    Plan
                  </label>
                  <select
                    id="plan"
                    name="plan"
                    value={newMember.membershipPlan}
                    onChange={(e) =>
                      setNewMember({
                        ...newMember,
                        membershipPlan: e.target.value,
                      })
                    }
                    className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                  >
                    <option value="">Select a Plan</option>
                    {plans.map((plan) => (
                      <option key={plan._id} value={plan._id}>
                        {plan?.plan_name}
                      </option>
                    ))}
                  </select>
                  <span className="text-red-600 text-sm">{planError}</span>
                </div>

                <div className="w-full md:w-1/3 px-2">
                  <label htmlFor="plan" className="text-sm text-gray-600">
                    Diet Plan
                  </label>
                  <select
                    id="dietPlan"
                    name="dietPlan"
                    value={newMember.dietPlan}
                    onChange={(e) =>
                      setNewMember({
                        ...newMember,
                        dietPlan: e.target.value,
                      })
                    }
                    className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                  >
                    <option value="">Select a Diet Plan</option>
                    {dietPlans.map((dietPlan) => (
                      <option key={dietPlan._id} value={dietPlan._id}>
                        {dietPlan?.plan_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-full md:w-1/3 px-2 flex justify-end">
                  <button
                    className="text-emerald-600 border border-gray-100 px-3 py-2 rounded-lg shadow-lg text-sm mt-6"
                    type="button"
                    onClick={() => {
                      setShow(false);
                      setSelectedButton(null);
                    }}
                  >
                    Close
                  </button>
                  <button
                    className="bg-emerald-600 text-gray-100 px-3 py-2 rounded-lg shadow-lg text-sm mt-6 ml-2"
                    type="button"
                    onClick={handleAddMember}
                  >
                    Add Member
                  </button>
                </div>
                <div className="flex justify-center w-full">
                  <span className="font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                    {messageResponse}
                  </span>
                </div>
              </form>
            )}
            {showDate && (
              <form className="flex flex-wrap justify-end mt-4">
                {/* First Row */}
                <div className="w-full md:w-1/3 px-2">
                  <label htmlFor="name" className="text-sm text-gray-600">
                    Name
                  </label>
                  <input
                    id="name"
                    type="text"
                    name="name"
                    value={newMember.name}
                    onChange={(e) =>
                      setNewMember({ ...newMember, name: e.target.value })
                    }
                    className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                    placeholder="Name"
                  />
                  <span className="text-red-600 text-sm">{nameError}</span>
                </div>

                <div className="w-full md:w-1/3 px-2">
                  <label htmlFor="mobile" className="text-sm text-gray-600">
                    Mobile
                  </label>
                  <input
                    id="mobile"
                    type="number"
                    name="mobile"
                    value={newMember.mobile}
                    onChange={(e) =>
                      setNewMember({ ...newMember, mobile: e.target.value })
                    }
                    className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                    placeholder="Mobile"
                  />
                  <span className="text-red-600 text-sm">{mobileError}</span>
                </div>

                <div className="w-full md:w-1/3 px-2">
                  <label htmlFor="email" className="text-sm text-gray-600">
                    Email
                  </label>
                  <input
                    id="email"
                    type="email"
                    name="email"
                    value={newMember.email}
                    onChange={(e) =>
                      setNewMember({ ...newMember, email: e.target.value })
                    }
                    className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                    placeholder="Email"
                  />
                  <span className="text-red-600 text-sm">{emailError}</span>
                </div>

                {/* Second Row */}
                <div className="w-full md:w-1/4 px-2">
                  <label htmlFor="password" className="text-sm text-gray-600">
                    Password
                  </label>
                  <input
                    id="password"
                    type="text"
                    name="password"
                    value={newMember.password}
                    onChange={(e) =>
                      setNewMember({ ...newMember, password: e.target.value })
                    }
                    className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                    placeholder="Password"
                  />
                  <span className="text-red-600 text-sm">{passwordError}</span>
                </div>
                <div className="w-full md:w-1/4 px-2">
                  <label htmlFor="date" className="text-sm text-gray-600">
                    Date
                  </label>
                  <br></br>
                  <DatePicker
                    id="date"
                    selected={newMember.date}
                    onChange={(date) => setNewMember({ ...newMember, date })}
                    className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                    placeholderText="Select Date"
                    dateFormat="yyyy-MM-dd"
                  />
                  <span className="text-red-600 text-sm">{dateError}</span>
                </div>
                <div className="w-full md:w-1/4 px-2">
                  <label htmlFor="plan" className="text-sm text-gray-600">
                    Plan
                  </label>
                  <select
                    id="plan"
                    name="plan"
                    value={newMember.membershipPlan}
                    onChange={(e) =>
                      setNewMember({
                        ...newMember,
                        membershipPlan: e.target.value,
                      })
                    }
                    className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                  >
                    <option value="">Select a Plan</option>
                    {plans.map((plan) => (
                      <option key={plan.id} value={plan.id}>
                        {plan?.plan_name}
                      </option>
                    ))}
                  </select>
                  <span className="text-red-600 text-sm">{planError}</span>
                </div>

                <div className="w-full md:w-1/4 px-2">
                  <label htmlFor="plan" className="text-sm text-gray-600">
                    Diet Plan
                  </label>
                  <select
                    id="dietPlan"
                    name="dietPlan"
                    value={newMember.dietPlan}
                    onChange={(e) =>
                      setNewMember({
                        ...newMember,
                        dietPlan: e.target.value,
                      })
                    }
                    className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                  >
                    <option value="">Select a Diet Plan</option>
                    {dietPlans.map((dietPlan) => (
                      <option key={dietPlan.id} value={dietPlan.id}>
                        {dietPlan?.plan_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-full md:w-1/3 px-2 flex justify-end">
                  <button
                    className="text-emerald-600 border border-gray-100 px-3 py-2 rounded-lg shadow-lg text-sm mt-6"
                    type="button"
                    onClick={() => {
                      setShowDate(false);
                      setSelectedButton(null);
                    }}
                  >
                    Close
                  </button>
                  <button
                    className="bg-emerald-600 text-gray-100 px-3 py-2 rounded-lg shadow-lg text-sm mt-6 ml-2"
                    type="button"
                    onClick={handleAddMember}
                  >
                    Add Member
                  </button>
                </div>
                <div className="flex justify-center w-full">
                  <span className="font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                    {messageResponse}
                  </span>
                </div>
              </form>
            )}
            {showExisting && (
              <form className="flex flex-wrap justify-end mt-4">
                {/* First Row */}
                <div className="w-full md:w-1/4 px-2">
                  <label htmlFor="name" className="text-sm text-gray-600">
                    Name
                  </label>
                  <input
                    id="name"
                    type="text"
                    name="name"
                    value={newMember.name}
                    onChange={(e) =>
                      setNewMember({ ...newMember, name: e.target.value })
                    }
                    className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                    placeholder="Name"
                  />
                  <span className="text-red-600 text-sm">{nameError}</span>
                </div>

                <div className="w-full md:w-1/4 px-2">
                  <label htmlFor="mobile" className="text-sm text-gray-600">
                    Mobile
                  </label>
                  <input
                    id="mobile"
                    type="number"
                    name="mobile"
                    value={newMember.mobile}
                    onChange={(e) =>
                      setNewMember({ ...newMember, mobile: e.target.value })
                    }
                    className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                    placeholder="Mobile"
                  />
                  <span className="text-red-600 text-sm">{mobileError}</span>
                </div>

                <div className="w-full md:w-1/4 px-2">
                  <label htmlFor="email" className="text-sm text-gray-600">
                    Email
                  </label>
                  <input
                    id="email"
                    type="email"
                    name="email"
                    value={newMember.email}
                    onChange={(e) =>
                      setNewMember({ ...newMember, email: e.target.value })
                    }
                    className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                    placeholder="Email"
                  />
                  <span className="text-red-600 text-sm">{emailError}</span>
                </div>

                {/* Second Row */}
                <div className="w-full md:w-1/4 px-2">
                  <label htmlFor="password" className="text-sm text-gray-600">
                    Password
                  </label>
                  <input
                    id="password"
                    type="text"
                    name="password"
                    value={newMember.password}
                    onChange={(e) =>
                      setNewMember({ ...newMember, password: e.target.value })
                    }
                    className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                    placeholder="Password"
                  />
                  <span className="text-red-600 text-sm">{passwordError}</span>
                </div>
                <div className="w-full md:w-1/4 px-2">
                  <label htmlFor="date" className="text-sm text-gray-600">
                    Start Date
                  </label>
                  <br></br>
                  <DatePicker
                    id="date"
                    selected={newMember.startDate}
                    onChange={(startDate) => setNewMember({ ...newMember, startDate })}
                    className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                    placeholderText="Select Date"
                    dateFormat="yyyy-MM-dd"
                  />
                  <span className="text-red-600 text-sm">{dateError}</span>
                </div>
                <div className="w-full md:w-1/4 px-2">
                  <label htmlFor="date" className="text-sm text-gray-600">
                    End Date
                  </label>
                  <br></br>
                  <DatePicker
                    id="date"
                    selected={newMember.endDate}
                    onChange={(endDate) => setNewMember({ ...newMember, endDate })}
                    className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                    placeholderText="Select Date"
                    dateFormat="yyyy-MM-dd"
                  />
                  <span className="text-red-600 text-sm">{dateError}</span>
                </div>
                <div className="w-full md:w-1/4 px-2">
                  <label htmlFor="plan" className="text-sm text-gray-600">
                    Plan
                  </label>
                  <select
                    id="plan"
                    name="plan"
                    value={newMember.membershipPlan}
                    onChange={(e) =>
                      setNewMember({
                        ...newMember,
                        membershipPlan: e.target.value,
                      })
                    }
                    className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                  >
                    <option value="">Select a Plan</option>
                    {plans.map((plan) => (
                      <option key={plan.id} value={plan.id}>
                        {plan?.plan_name}
                      </option>
                    ))}
                  </select>
                  <span className="text-red-600 text-sm">{planError}</span>
                </div>

                <div className="w-full md:w-1/4 px-2">
                  <label htmlFor="plan" className="text-sm text-gray-600">
                    Diet Plan
                  </label>
                  <select
                    id="dietPlan"
                    name="dietPlan"
                    value={newMember.dietPlan}
                    onChange={(e) =>
                      setNewMember({
                        ...newMember,
                        dietPlan: e.target.value,
                      })
                    }
                    className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full py-2 focus:outline-none focus:border-emerald-400"
                  >
                    <option value="">Select a Diet Plan</option>
                    {dietPlans.map((dietPlan) => (
                      <option key={dietPlan.id} value={dietPlan.id}>
                        {dietPlan?.plan_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-full md:w-1/3 px-2 flex justify-end">
                  <button
                    className="text-emerald-600 border border-gray-100 px-3 py-2 rounded-lg shadow-lg text-sm mt-6"
                    type="button"
                    onClick={() => {
                      setShowExisting(false);
                      setSelectedButton(null);
                    }}
                  >
                    Close
                  </button>
                  <button
                    className="bg-emerald-600 text-gray-100 px-3 py-2 rounded-lg shadow-lg text-sm mt-6 ml-2"
                    type="button"
                    onClick={handleAddMember}
                  >
                    Add Member
                  </button>
                </div>
                <div className="flex justify-center w-full">
                  <span className="font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                    {messageResponse}
                  </span>
                </div>
              </form>
            )}
          </div>
        </div>
        {/* Main Content */}
        <div className="mainCard">
          <div className="border w-full border-gray-200 bg-white py-4 px-6 rounded-md">
            <div className="relative mb-3">
              <div className="inline-flex justify-center absolute left-0 top-[0.85rem] h-full w-10 text-gray-400">
                <FontAwesomeIcon icon={faSearch} />
              </div>
              <input
                id="search"
                type="search"
                name="search"
                value={searchText}
                onChange={(e) => handleSearch(e.target.value)}
                className="text-sm placeholder-gray-500 pl-10 px-4 rounded-lg border border-gray-200 md:py-2 py-3 focus:outline-none focus:border-emerald-400 mt-1 w-full md:w-64"
                placeholder="Search"
              />
            </div>
            <div
              className={`${
                showSuccessMessage ? "block" : "hidden"
              } bg-green-400 text-white text-center py-2 px-4 mt-4 mb-4 rounded-md`}
            >
              Member added successfully!
            </div>
            <div
              className={`${
                showDeleteMessage ? "block" : "hidden"
              } bg-red-400 text-white text-center py-2 px-4 mt-4 mb-4 rounded-md`}
            >
              Member deleted successfully!
            </div>
            <MemberTable
              loading={loading}
              dataHeader={dataHeader}
              data={data}
              handleDelete={handleDelete}
              searchText={searchText}
              handleUserId={handleRenew}
            />
            <div className="flex justify-center mt-2">
              <button
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                className="bg-emerald-600 text-gray-100 px-3 py-2 rounded-lg shadow-lg text-sm"
              >
                Previous Page
              </button>
              <p style={{ margin: "0 10px" }}>
                {currentPage} / {totalPages}
              </p>
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                className="bg-emerald-600 text-gray-100 px-3 py-2 rounded-lg shadow-lg text-sm"
              >
                Next Page
              </button>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Member;
