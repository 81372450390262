import React from "react";
import Datatables from "../components/Datatables/Table";
import DietTableCell from "../components/Datatables/DietTableCell";

function GymFeedbacktable({ loading, dataHeader, data , action ,markAsSeen }) {
  const handleMarkAsSeen = (feedbackId) => {
    markAsSeen(feedbackId);
  };
  const handleAction = (feedbackId) => {
    action(feedbackId);
  };
  return (
    <Datatables loading={loading} dataHeader={dataHeader}>
      {data?.map((row, index) => (
        <tr
          key={index}
          className="bg-white border md:border-b block md:table-row rounded-md shadow-md md:rounded-none md:shadow-none mb-5"
        >
          <DietTableCell dataLabel="Feedback" showLabel={true}>
            <span className="font-medium text-sm text-gray-900">
              {row.name}
            </span>
          </DietTableCell>
          <DietTableCell dataLabel="Feedback" showLabel={true}>
            <span className="font-medium text-sm text-gray-900">
              {row.message}
            </span>
          </DietTableCell>
          <DietTableCell dataLabel="Sent On" showLabel={true}>
            <p className="font-normal text-sm text-gray-500">{new Date(row.createdAt).toLocaleString()}</p>
          </DietTableCell>
          <DietTableCell dataLabel="Status" showLabel={true}>
            <p className="font-normal text-sm text-gray-500">{row.seen? "Marked as Seen" :<button className="bg-emerald-600 text-gray-100 p-1 rounded-lg shadow-lg text-sm" onClick={() => handleMarkAsSeen(row.id)}>Mark as seen</button>}</p>
          </DietTableCell>
          <DietTableCell dataLabel="Action" showLabel={true}>
            <p className="font-normal text-sm text-gray-500">{row.actionTaken? row.actionTaken : <button className="bg-emerald-600 text-gray-100 p-1 rounded-lg shadow-lg text-sm" onClick={() => handleAction(row.id)}>Add Action</button>}</p>
          </DietTableCell>
        </tr>
      ))}
    </Datatables>
  );
}

export default GymFeedbacktable;
